<template>
    <div>
        <div>
            <CrudIndex
                v-if="!loading"
                title="Quiz Question"
                :viewColumns="columns"
                fetchUrl="/quiz-questions"
                createUrl="/quiz-questions"
                updateRootUrl="/quiz-questions"
                deleteRootUrl="/quiz-questions"
                :createFields="createFields"
                :onlyEdit="true"
                :onlyEditData="questionData"
                :allowGoBack="true"
                :backRoute="`/quiz-question`"
                :editingFormatter="editingFormatter"
            />
        </div>

        <div class="my-4">
            <h3 class="h5">Answer Options</h3>
            <hr />
        </div>
        <!-- <AnswerOptionIndex :questionId="$route.params.id" /> -->
        <AnswerOptionCreator :questionId="$route.params.id" />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import AnswerOptionIndex from "@/components/AnswerOptionIndex"
import AnswerOptionCreator from "@/components/answer-option/AnswerOptionCreator"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            loading: true,
            questionData: null,
            createFields: [],
            columns: [
                createColumn("Id", "_id", "number"),
                createColumn("Question", "title", "string"),
                createColumn("Subject", "subjectId.title", "string"),
            ],
        }
    },
    components: { CrudIndex, AnswerOptionIndex, AnswerOptionCreator },
    async mounted() {
        this.fetchQuestion()
        const subjects = await this.fetchSubjects()
        this.createFields = [
            createField("Title*", "title", "text", true),
            createField(
                "Subject*",
                "subjectId",
                "select",
                true,
                "",
                subjects.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField("Description", "description", "textarea", false),
            createField("Related Image", "imageUrl", "image", false),
            createField("Status", "status", "switch", false, true),
        ]
    },
    methods: {
        async fetchQuestion() {
            this.loading = true
            const res = await http.get("/quiz-questions/" + this.$route.params.id)
            this.questionData = res.data.result
            this.loading = false
        },
        async fetchSubjects() {
            const res = await http.get("/subjects")
            return res.data.result
        },
        editingFormatter(item) {
            console.log("editing formatter", item)
            return {
                ...item,
                subjectId: item.subjectId ? item.subjectId._id : "",
            }
        },
    },
}
</script>

<style></style>
