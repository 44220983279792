<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary"></div>
        </div>
        <div v-else>
            <div>
                <Button
                    :style="`opacity: ${transcriptionUnderProcess ? 0.5 : 1}`"
                    :disabled="transcriptionUnderProcess"
                    class="px-3"
                    @click="showConfirmModal = true"
                >
                    <i class="fas fa-file"></i> Transcribe Video Using AI
                </Button>
                <div v-if="transcriptionUnderProcess">
                    Status: Transcribing
                    <div class="spinner-border spinner-border-sm text-dark"></div>
                </div>
                <div v-else-if="!video.videoTranscribedLastDate">Video Not Transcribed.</div>
                <p v-else>Video Last Transcribed on: {{ video.videoTranscribedLastDate }}</p>
                <p>Please refresh the page to get its latest status.</p>
            </div>
            <CustomModal :show="showConfirmModal">
                <template #header> Are you sure to start transcription? </template>
                <div style="font-weight: normal">
                    <p class="my-2">
                        Transcribing This Video will use the Google Speech To Text API and overwrite the current transcribed data!
                    </p>
                    <p>Transcription is a background process and it will incur additional costs!</p>
                </div>
                <template #footer>
                    <Button @click="showConfirmModal = false">Cancel</Button>
                    <Button @click="startTranscription">Confirm</Button>
                </template>
            </CustomModal>

            <div v-if="subTitles.length">
                <div v-for="subTitle of subTitles" :key="subTitle._id">
                    <div class="mb-3 my-3 d-flex justify-content-between">
                        <div class="">
                            Start Time: {{ durify(subTitle.startTimeInSeconds) }} <br />
                            Update Start Time (Seconds):
                            <input
                                class="form-control-sm"
                                style="border: 1px solid #aaa; width: 100px; background: #eee; font-size: 0.9em"
                                type="number"
                                v-model="subTitle.startTimeInSeconds"
                            />
                        </div>
                        <div class="">
                            End Time: {{ durify(subTitle.endTimeInSeconds) }} <br />
                            Update End Time (Seconds):
                            <input
                                class="form-control-sm"
                                style="border: 1px solid #aaa; width: 100px; background: #eee; font-size: 0.9em"
                                type="number"
                                v-model="subTitle.endTimeInSeconds"
                            />
                        </div>
                    </div>
                    <input type="text" class="form-control" v-model="subTitle.subtitle" />
                </div>
                <Button @click="updateSubTitles">{{ !updatingSubtitles ? "Update Subtitles" : "Please wait..." }}</Button>
            </div>
            <!-- <div>
      <p class="mb-2">Caption/Subtitle</p>
      <input type="text" class="form-control" />
      <div class="d-flex my-3" style="gap: 16px">
        <div class="">
          <p>Start Time</p>
          <TimeBar
            :id="`timebar`"
            :min="Number(0)"
            :max="Number(100)"
            @change="updateTimeInputValue"
            :value="timeInputValue"
          />
        </div>
        <div class="">
          <p>End Time</p>
          <TimeBar
            :id="`timebar`"
            :min="Number(0)"
            :max="Number(100)"
            @change="updateTimeInputValue"
            :value="timeInputValue"
          />
        </div>
      </div>
      <Button><i class="fas fa-plus"></i> Add Subtitle</Button>
    </div> -->
        </div>
    </div>
</template>

<script>
import CrudIndex from "../../../components/crud/index.vue"
import Button from "@/components/UI/Button"
import TimeBar from "@/components/crud/TimeBar.vue"
import CustomModal from "@/components/layout/CustomModal.vue"
import { http } from "@/utils/http"
import { durify } from "@/utils/common"

export default {
    data() {
        return {
            timeInputValue: "",
            showConfirmModal: false,
            subTitles: [],
            video: null,
            updatingSubtitles: false,
            loading: true,
        }
    },
    components: { CrudIndex, Button, TimeBar, CustomModal },
    async mounted() {
        await this.fetchSubTitles()
    },
    methods: {
        durify,
        // updateTimeInputValue(field, value) {
        //   console.log("field value", value);
        // },
        async fetchSubTitles() {
            this.loading = true
            try {
                const res = await http.get(`/video-subtitles?video_id=${this.$route.params.id}`)
                this.video = res.data.video
                this.subTitles = res.data.result
            } catch (err) {
                console.log("error in fetching subtitles", err)
            }
            this.loading = false
        },
        async startTranscription() {
            try {
                const res = await http.patch(`/videos/${this.$route.params.id}/transcribe`)
                this.video = res.data.video
                this.$toast.success("Video Transcription started successfully.")
            } catch (err) {
                console.log("error in fetching subtitles", err)
                this.$toast.error("Couldn't transcribe video!")
            }
            this.showConfirmModal = false
        },
        async updateSubTitles() {
            this.updatingSubtitles = true
            try {
                await http.patch(`/video-subtitles`, {
                    subTitles: this.subTitles,
                })
                this.$toast.success("Subtitles updated successfully!")
            } catch (err) {
                console.log("error in updating subtitles", err)
                this.$toast.error("Error in updating subtitles")
            }
            this.updatingSubtitles = false
        },
    },
    computed: {
        transcriptionUnderProcess() {
            return this.video && this.video.videoTranscriptionUnderProcess
        },
    },
}
</script>

<style></style>
