<template>
    <div>
        <CrudIndex
            title="Ticket Categories"
            :viewColumns="columns"
            fetchUrl="/ticket-categories"
            createUrl="/ticket-categories"
            updateRootUrl="/ticket-categories"
            deleteRootUrl="/ticket-categories"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [createField("Name*", "name", "text", true)],
            columns: [createColumn("Id", "_id", "number"), createColumn("Name", "name", "string")],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
