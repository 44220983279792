<template>
    <div>
        <div class="text-black font-bold">
            Create Quiz Answer
            <span
                @click="showForm = !showForm"
                class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
            >
                <i class="fas fa-minus" v-if="showForm" />
                <i class="fas fa-add" v-else />
            </span>
        </div>
        <Card v-if="showForm">
            <form class="my-3" @submit.prevent="createQuizAnswer">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-2">Quiz</div>

                        <div>
                            <select
                                required
                                class="form-select"
                                v-model="quizQuestionMasterId"
                                aria-label=".form-select-sm example"
                            >
                                <option selected disabled>Select Quiz</option>
                                <option
                                    v-for="quizMasterItem of quizMaster"
                                    :value="quizMasterItem._id"
                                    :key="quizMasterItem._id"
                                >
                                    {{ quizMasterItem.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-2">Answer Options</div>
                        <div>
                            <select
                                required
                                class="form-select"
                                v-model="quizAnswerOptionMasterId"
                                aria-label=".form-select-sm example"
                            >
                                <option selected disabled>Select Answer Option</option>
                                <option
                                    v-for="answerOption of answerOptions"
                                    :value="answerOption._id"
                                    :key="answerOption._id"
                                >
                                    {{ answerOption.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ creatingQuizAnswer ? "Please wait..." : "Create" }}
                </Button>
            </form>
        </Card>
        <Card>
            <h1 class="font-semibold mb-3">Quiz Answer</h1>
            <div class="grid grid-cols-3 bg-green-200 p-2">
                <div>Id</div>
                <div>Quiz</div>
                <div>Action</div>
            </div>
            <div class="p-4 text-center text-sm" v-if="quizAnswers.length <= 0 && initialized">
                No videos Found
            </div>
            <div
                class="grid grid-cols-3 p-2"
                v-for="quizAnswer of quizAnswers"
                :key="quizAnswer._id"
            >
                <div>{{ quizAnswer._id }}</div>
                <div>
                    <div>
                        {{ quizAnswer.quizQuestionMasterId }}
                    </div>
                </div>

                <div>
                    <router-link
                        :to="{
                            name: 'quiz-answer-edit',
                            params: { id: quizAnswer._id },
                        }"
                    >
                        <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
                    </router-link>
                    <i
                        class="cursor-pointer text-red-500 fas fa-trash"
                        @click="deleteQuizAnswer(quizAnswer._id)"
                    />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "../../components/UI/Card.vue"
import Button from "../../components/UI/Button.vue"
import { http } from "../../utils/http"
import HtmlEditor from "../../components/other/HtmlEditor.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            quizAnswers: [],
            quizQuestionMasterId: "",
            quizAnswerOptionMasterId: "",

            quizMaster: [],
            answerOptions: [],
            showForm: false,
            initialized: false,
            creatingQuizAnswer: false,
        }
    },
    mounted() {
        this.loadQuizQuestions()
        this.loadQuiz()
    },
    methods: {
        async loadQuiz() {
            try {
                const res = await http.get("/quiz-questions")
                const res2 = await http.get("/quiz-answer-options")
                this.quizMaster = res.data.result
                this.answerOptions = res2.data.result
            } catch (err) {
                console.log("error in loading quizItems", err)
            }
            this.initialized = true
        },
        async loadQuizQuestions() {
            try {
                const res = await http.get("/quiz-answers")
                this.quizAnswers = res.data.result
            } catch (err) {
                console.log("error in loading Quiz quizAnswers", err)
            }
            this.initialized = true
        },

        async createQuizAnswer() {
            this.creatingQuizAnswer = true
            try {
                const createdRes = await http.post("/quiz-answers", {
                    quizQuestionMasterId: this.quizQuestionMasterId,
                    quizAnswerOptionMasterId: this.quizAnswerOptionMasterId,
                })
                const createdDataItem = createdRes.data.result

                this.quizQuestionMasterId = ""
                this.quizAnswerOptionMasterId = ""
                this.$toast.success("Quiz Answer Created Successfully")
                window.location.href = "/quiz-answer"
            } catch (err) {
                console.log("error in creating Quiz Answer", err)
                this.$toast.error("Some error occured")
            }
            this.creatingQuizAnswer = false
        },
        async deleteQuizAnswer(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete("/quiz-answers/" + id)
                    this.$toast.success("Quiz Answer Deleted Successfully")
                    this.quizAnswers = this.quizAnswers.filter((item) => item.id !== id)
                    window.location.href = "/quiz-answer"
                }
            } catch (err) {
                console.log("error in creating Quiz Answer", err)
                this.$toast.error("Some error occured")
            }
        },
    },
}
</script>

<style></style>
