<template>
    <div>
        <CrudIndex
            title="Subject"
            :viewColumns="columns"
            fetchUrl="/subjects"
            createUrl="/subjects"
            updateRootUrl="/subjects"
            deleteRootUrl="/subjects"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Title*", "title", "text", true),
                createField("Description", "description", "text", false),
                createField("Image", "thumbnail", "image", false),
                createField("Status", "status", "switch", false, true),
            ],
            columns: [
                createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
