import Dashboard from "../pages/dashboard.vue"
import Login from "../pages/login.vue"
// fresh ecom new components start
import SubjectMaster from "../pages/subject-master/index.vue"
import StateMaster from "../pages/state-master/index.vue"

import UserRole from "../pages/user-role/index.vue"
import EditUserRole from "../pages/user-role/_edit.vue"

import Users from "../pages/users/index.vue"
import EditUsers from "../pages/users/_edit.vue"

import Courses from "../pages/courses/index.vue"
import CourseCollection from "../pages/course-collections/index.vue"
import Videos from "../pages/videos/index.vue"
import EditVideoContainer from "../pages/videos/_edit.vue"
import EditVideo from "../pages/videos/_edit/index.vue"
import EditVideoQuizzes from "../pages/videos/_edit/quizzes.vue"
import EditVideoReviews from "../pages/videos/_edit/reviews.vue"
import EditVideoSubtitles from "../pages/videos/_edit/subtitles.vue"
import EditVideoChapters from "../pages/videos/_edit/chapters.vue"
import EditVideoDiscussions from "../pages/videos/_edit/discussion.vue"
import CustomDictionary from "../pages/custom-dictionary/index.vue"

import Quiz from "../pages/quiz/index.vue"
import EditQuiz from "../pages/quiz/_edit.vue"

import QuizQuestion from "../pages/quiz-question/index.vue"
import QuizQuestionEdit from "../pages/quiz-question/_edit.vue"

import QuizQuestionSelected from "../pages/quiz-question-selected/index.vue"
import EditQuizQuestionSelected from "../pages/quiz-question-selected/_edit.vue"

import QuizAnswerOption from "../pages/quiz-answer-option/index.vue"
import EditQuizAnswerOption from "../pages/quiz-answer-option/_edit.vue"

import QuizAnswer from "../pages/quiz-answer/index.vue"
import EditQuizAnswer from "../pages/quiz-answer/_edit.vue"

import QuizHistory from "../pages/quiz-history/index.vue"
import EditQuizHistory from "../pages/quiz-history/_edit.vue"

import VideoProgress from "../pages/video-progress/index.vue"
import EditVideoProgress from "../pages/video-progress/_edit.vue"

import SubjectProgress from "../pages/subject-progress/index.vue"
import EditSubjectProgress from "../pages/subject-progress/_edit.vue"

import VideoSubtitle from "../pages/video-subtitle/index.vue"
import EditVideoSubtitle from "../pages/video-subtitle/_edit.vue"

import VideoChapter from "../pages/video-chapter/index.vue"
import EditVideoChapter from "../pages/video-chapter/_edit.vue"

import Reviews from "../pages/reviews/index.vue"
import EditReviews from "../pages/reviews/_edit.vue"

import Discussion from "../pages/discussions/index.vue"
import EditDiscussion from "../pages/discussions/_edit.vue"

import Certificates from "../pages/certificates/index.vue"
import EditCertificates from "../pages/certificates/_edit.vue"
import MyProfile from "../pages/my-profile.vue"

import AnalyticReports from "../pages/reports/analytic-reports.vue"
import QuizReports from "../pages/reports/quiz-reports.vue"
import VideoReports from "../pages/reports/video-reports.vue"

import GeographicalStats from "../pages/reports/geographical-stats.vue"
import SubjectStats from "../pages/reports/subject-stats.vue"
import UserStats from "../pages/reports/user-stats.vue"
import VideoStats from "../pages/reports/video-stats.vue"
import Events from "../pages/events/index.vue"
import EventDetails from "../pages/events/_edit.vue"

// tickets
import Tickets from "../pages/tickets/index.vue"
import TicketStatuses from "../pages/ticket-statuses/index.vue"
import TicketPriorities from "../pages/ticket-priorities/index.vue"
import TicketCategories from "../pages/ticket-categories/index.vue"

const routes = [
    { path: "/", component: Login, redirect: "/dashboard" },
    { path: "/dashboard", component: Dashboard },
    { path: "/my-profile", component: MyProfile },
    { path: "/users", component: Users },
    { path: "/reports", component: AnalyticReports },
    { path: "/courses", component: Courses },
    { path: "/reports/quiz", component: QuizReports },
    { path: "/reports/videos", component: VideoReports },
    // other reports
    { path: "/reports/geographical-stats", component: GeographicalStats },
    { path: "/reports/subject-stats", component: SubjectStats },
    { path: "/reports/user-stats", component: UserStats },
    { path: "/reports/video-stats", component: VideoStats },

    {
        path: "/users/:id",
        // alias: "/setting/:id",
        component: EditUsers,
        name: "user-edit",
    },
    //fresh ecomnew routes path
    { path: "/subject-master", component: SubjectMaster },
    { path: "/state-master", component: StateMaster },

    { path: "/user-roles", component: UserRole },
    { path: "/collections", component: CourseCollection },

    {
        path: "/user-roles/:id",
        // alias: "/subject-master/:id",
        component: EditUserRole,
        name: "user-role-edit",
    },

    { path: "/videos", component: Videos },
    { path: "/custom-dictionary", component: CustomDictionary },

    {
        path: "/videos/:id",
        // alias: "/subject-master/:id",
        component: EditVideoContainer,
        name: "video-edit",
        children: [
            {
                path: "",
                component: EditVideo,
                name: "video-edit.general",
            },
            {
                path: "quizzes",
                component: EditVideoQuizzes,
                name: "video-edit.quizzes",
            },
            {
                path: "reviews",
                component: EditVideoReviews,
                name: "video-edit.reviews",
            },
            {
                path: "subtitles",
                component: EditVideoSubtitles,
                name: "video-edit.subtitles",
            },
            {
                path: "chapters",
                component: EditVideoChapters,
                name: "video-edit.chapters",
            },
            {
                path: "discussion",
                component: EditVideoDiscussions,
                name: "video-edit.discussion",
            },
        ],
    },
    { path: "/quiz", component: Quiz },
    {
        path: "/quiz/:id",
        // alias: "/subject-master/:id",
        component: EditQuiz,
        name: "quiz-edit",
    },

    { path: "/quiz-question", component: QuizQuestion },
    {
        path: "/quiz-question/:id",
        // alias: "/subject-master/:id",
        component: QuizQuestionEdit,
        name: "quiz-question-edit",
    },

    { path: "/quiz-question-selected", component: QuizQuestionSelected },

    {
        path: "/quiz-question-selected/:id",
        // alias: "/subject-master/:id",
        component: EditQuizQuestionSelected,
        name: "quiz-question-selected-edit",
    },
    { path: "/quiz-answer-option", component: QuizAnswerOption },

    {
        path: "/quiz-answer-option/:id",
        // alias: "/subject-master/:id",
        component: EditQuizAnswerOption,
        name: "quiz-answer-option-edit",
    },

    { path: "/quiz-answer", component: QuizAnswer },

    {
        path: "/quiz-answer/:id",
        // alias: "/subject-master/:id",
        component: EditQuizAnswer,
        name: "quiz-answer-edit",
    },

    { path: "/quiz-history", component: QuizHistory },

    {
        path: "/quiz-history/:id",
        // alias: "/subject-master/:id",
        component: EditQuizHistory,
        name: "quiz-history-edit",
    },

    { path: "/video-progress", component: VideoProgress },

    {
        path: "/video-progress/:id",
        // alias: "/subject-master/:id",
        component: EditVideoProgress,
        name: "video-progress-edit",
    },
    { path: "/subject-progress", component: SubjectProgress },

    {
        path: "/subject-progress/:id",
        // alias: "/subject-master/:id",
        component: EditSubjectProgress,
        name: "subject-progress-edit",
    },

    { path: "/video-subtitle", component: VideoSubtitle },

    {
        path: "/video-subtitle/:id",
        // alias: "/subject-master/:id",
        component: EditVideoSubtitle,
        name: "video-subtitle-edit",
    },

    { path: "/video-chapter", component: VideoChapter },

    {
        path: "/video-chapter/:id",
        // alias: "/subject-master/:id",
        component: EditVideoChapter,
        name: "video-chapter-edit",
    },
    { path: "/reviews", component: Reviews },

    {
        path: "/reviews/:id",
        component: EditReviews,
        name: "review-edit",
    },

    { path: "/discussions", component: Discussion },
    { path: "/events", component: Events },
    { path: "/events/:id", component: EventDetails },

    {
        path: "/discussions/:id",
        component: EditDiscussion,
        name: "discussion-edit",
    },

    { path: "/certificates", component: Certificates },

    {
        path: "/certificates/create",
        component: EditCertificates,
        name: "certificate-create",
    },
    {
        path: "/certificates/:id",
        component: EditCertificates,
        name: "certificate-edit",
    },

    /**
     * TICKETS
     */

    { path: "/tickets", name: "tickets", component: Tickets },
    { path: "/ticket-statuses", name: "ticket-statuses", component: TicketStatuses },
    { path: "/ticket-priorities", name: "ticket-priorities", component: TicketPriorities },
    { path: "/ticket-categories", name: "ticket-categories", component: TicketCategories },
]

export default routes
