<template>
    <div>
        <CrudIndex
            title="Word Meaning"
            :viewColumns="columns"
            fetchUrl="/custom-dictionary"
            createUrl="/custom-dictionary"
            updateRootUrl="/custom-dictionary"
            deleteRootUrl="/custom-dictionary"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Word*", "word", "text", true),

                createField("English Meaning/Definition (Required)", "meaning", "text", true),
                createField("English Example", "example", "text", false),

                createField("Hindi Meaning/Definition", "hindi_meaning", "text", false),
                createField("Hindi Example", "hindi_example", "text", false),

                createField("Marathi Meaning/Definition", "marathi_meaning", "text", false),
                createField("Marathi Example", "marathi_example", "text", false),

                createField("Tamil Meaning/Definition", "tamil_meaning", "text", false),
                createField("Tamil Example", "tamil_example", "text", false),

                createField("Telugu Meaning/Definition", "telugu_meaning", "text", false),
                createField("Telugu Example", "telugu_example", "text", false),
            ],
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Word", "word", "string"),
                createColumn("Meaning", "meaning", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
