<template>
  <div>
    <div class="text-black font-bold">
      Create Video Chapter
      <span
        @click="showForm = !showForm"
        class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
      >
        <i class="fas fa-minus" v-if="showForm" />
        <i class="fas fa-add" v-else />
      </span>
    </div>
    <Card v-if="showForm">
      <form class="my-3" @submit.prevent="createVideoChapter">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">Video</div>

            <div>
              <select
                required
                class="form-select"
                v-model="videoId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select Video</option>
                <option
                  v-for="video of videos"
                  :value="video._id"
                  :key="video._id"
                >
                  {{ video.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">Title</div>
            <div>
              <input
                required
                v-model="title"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">StartTimeInSeconds</div>
            <div>
              <input
                required
                v-model="startTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">EndTimeInSeconds</div>
            <div>
              <input
                required
                v-model="endTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ creatingVideoChapter ? "Please wait..." : "Create" }}
        </Button>
      </form>
    </Card>
    <Card>
      <h1 class="font-semibold mb-3">Video Subtitle</h1>
      <div class="grid grid-cols-3 bg-green-200 p-2">
        <div>Id</div>
        <div>Title</div>
        <div>Action</div>
      </div>
      <div
        class="p-4 text-center text-sm"
        v-if="videoChapters.length <= 0 && initialized"
      >
        No videoChapters Found
      </div>
      <div
        class="grid grid-cols-3 p-2"
        v-for="videoChapter of videoChapters"
        :key="videoChapter._id"
      >
        <div>{{ videoChapter._id }}</div>
        <div>{{ videoChapter.title }}</div>

        <div>
          <router-link
                  :to="{
                    name: 'video-chapter-edit',
                    params: { id: videoChapter._id },
                  }"
                >
                  <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
                </router-link>
          <i
            class="cursor-pointer text-red-500 fas fa-trash"
            @click="deleteVideoChapters(videoChapter._id)"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from "../../components/UI/Card.vue";
  import Button from "../../components/UI/Button.vue";
  import { http } from "../../utils/http";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        videoId: "",
        startTimeInSeconds: "",
        endTimeInSeconds: "",
        title: "",

        videos: [],

        videoChapters: [],
        showForm: false,
        initialized: false,
        creatingVideoChapter: false,
      };
    },
    mounted() {
      this.loadVideoChapters();
      this.loadVideo();
    },
    methods: {
      async loadVideo() {
        try {
          const res2 = await http.get("/videos");

          this.videos = res2.data.result;
        } catch (err) {
          console.log("error in loading videos", err);
        }
        this.initialized = true;
      },
      async loadVideoChapters() {
        try {
          const res = await http.get("/video-chapters");
          this.videoChapters = res.data.result;
        } catch (err) {
          console.log("error in loading chapter", err);
        }
        this.initialized = true;
      },

      async createVideoChapter() {
        this.creatingVideoChapter = true;
        try {
          await http.post("/video-chapters", {
            videoId: this.videoId,
            startTimeInSeconds: this.startTimeInSeconds,
            endTimeInSeconds: this.endTimeInSeconds,
            title: this.title,
          });

          this.videoId = "";
          this.startTimeInSeconds = "";
          this.endTimeInSeconds = "";
          this.title = "";

          this.$toast.success("Video Chapter Added Successfully");
          window.location.href = "/video-chapter";
        } catch (err) {
          console.log("error in Adding Video chapter", err);
          this.$toast.error("Some error occured");
        }

        this.creatingVideoChapter = false;
      },
      async deleteVideoChapters(id) {
        try {
          if (confirm("Do you really want to delete?")) {
            await http.delete("/video-chapters/" + id);
            this.$toast.success("Video-Chapter Deleted Successfully");
            this.videoChapters = this.videoChapters.filter(
              (item) => item.id !== id
            );
            window.location.href = "/video-chapter";
          }
        } catch (err) {
          console.log("error in creating Chapter", err);
          this.$toast.error("Some error occured");
        }
      },
    },
  };
</script>

<style></style>
