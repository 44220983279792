<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Events"
            :viewColumns="columns"
            fetchUrl="/events?no_populate=true"
            createUrl="/events"
            updateRootUrl="/events"
            deleteRootUrl="/events"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
            v-slot="slotProps"
        >
            <Button noMargin style="background: #55f" @click="viewEvent(slotProps.itemId)" class="crud-btn">
                View Event <i class="fas fa-external-link" />
            </Button>
        </CrudIndex>
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"
import Button from "../../components/UI/Button.vue"

export default {
    data() {
        return {
            subjects: [],
            admins: [],
            users: [],
            searchField: "",
            selectedSubject: "",
            loading: true,
            createFields: [],
            noResults: false,
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Active", "isActive", "string"),
            ],
        }
    },
    components: { Button, CrudIndex, SelectDropdown },
    async mounted() {
        await this.fetchUsers()

        this.createFields = [
            createField("Title*", "title", "text", true),
            createField("Description", "description", "textarea", false),
            createField("Date", "date", "date", false),
            createField("Status", "isActive", "switch", false, true),
            createField(
                "Speaker*",
                "speaker",
                "select",
                true,
                this.admins.length ? this.admins[0]._id : "",
                // [{ _id: "1", title: "Adminstrator" }]
                this.admins.slice(0, 1).map((item) => ({
                    _id: item._id,
                    title: `${item.firstName} ${item.lastName}`,
                }))
            ),
            createField(
                "Participants*",
                "participants",
                "multiselect",
                true,
                "",
                this.users.map((item) => ({
                    _id: item._id,
                    title: `${item.firstName} ${item.lastName}`,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        viewEvent(itemId) {
            window.open("/events/" + itemId, "_blank")
        },
        async fetchUsers() {
            const res = await http.get("/users")
            this.users = res.data.result
            this.admins = res.data.admins
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
    },
}
</script>

<style></style>
