<template>
    <div class="row" style="max-width: 400px">
        <div class="col">
            <label class="mb-1">Start Date</label>
            <input
                class="form-control form-control-sm"
                :max="maxStartDate"
                type="date"
                v-model="startDate"
            />
        </div>
        <div class="col">
            <label class="mb-1">End Date</label>
            <input
                class="form-control form-control-sm"
                :min="minEndDate"
                :max="maxEndDate"
                type="date"
                v-model="endDate"
            />
        </div>
    </div>
</template>

<script>
// this component allows max 3 months of data to be shown
import { daysToMs, formatIsoDate } from "@/utils/common"

export default {
    data() {
        return {
            startDate: "",
            endDate: formatIsoDate(),
            maxStartDate: formatIsoDate(),
            minEndDate: formatIsoDate(),
            maxEndDate: formatIsoDate(),
            initialized: false,
        }
    },
    mounted() {
        this.startDate = formatIsoDate(new Date().getTime() - daysToMs(this.maxRangeDays))
        this.handleChange()
    },
    props: {
        maxRangeDays: {
            type: Number,
            default: 60,
        },
        compactMode: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        initMinMaxDateRanges() {
            // allow max date till last 3 months
            this.minEndDate = this.startDate
            this.maxEndDate = formatIsoDate(
                new Date(this.startDate).getTime() + daysToMs(this.maxRangeDays)
            )
            if (this.maxEndDate > formatIsoDate()) {
                this.maxEndDate = formatIsoDate()
            }
            if (this.endDate > this.maxEndDate) {
                this.endDate = this.maxEndDate
            }
        },
        formatIsoDate,
        dateDifferenceValid() {
            // ensure date difference less than 3 months
            if (this.endDate < this.startDate) {
                return false
            }
            const isDifferenceLessThan3Months =
                new Date(this.endDate).getTime() - new Date(this.startDate).getTime() <
                daysToMs(this.maxRangeDays)

            return isDifferenceLessThan3Months
        },
        handleChange() {
            this.initMinMaxDateRanges()
            this.$emit("change", {
                startDate: this.startDate,
                endDate: this.endDate,
            })
            this.initialized = true
        },
    },
    watch: {
        startDate(e) {
            if (this.initialized) {
                this.handleChange()
            }
        },
        endDate(e) {
            if (this.initialized) {
                this.handleChange()
            }
        },
    },
}
</script>

<style></style>
