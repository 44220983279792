<template>
    <AnswerOptionIndex />
</template>

<script>
import AnswerOptionIndex from "@/components/AnswerOptionIndex"
export default {
    components: { AnswerOptionIndex },
}
</script>

<style></style>
