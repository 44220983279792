<template>
    <div>
        <div class="text-black font-bold">
            Create Review
            <span
                @click="showForm = !showForm"
                class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
            >
                <i class="fas fa-minus" v-if="showForm" />
                <i class="fas fa-add" v-else />
            </span>
        </div>
        <Card v-if="showForm">
            <form class="my-3" @submit.prevent="createReview">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-2">Users</div>

                        <div>
                            <select
                                required
                                class="form-select"
                                v-model="userId"
                                aria-label=".form-select-sm example"
                            >
                                <option selected disabled>Select User</option>
                                <option v-for="user of users" :value="user._id" :key="user._id">
                                    {{ user.firstName }} {{ user.lastName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-2">Videos</div>

                        <div>
                            <select
                                required
                                class="form-select"
                                v-model="videoId"
                                aria-label=".form-select-sm example"
                            >
                                <option selected disabled>Select Video</option>
                                <option v-for="video of videos" :value="video._id" :key="video._id">
                                    {{ video.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="my-2">Review title*</div>
                        <div>
                            <input required v-model="title" type="text" class="form-control" />
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="mb-2">Review Description</div>
                        <div>
                            <HtmlEditor @change="description = $event" v-model="description" />
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="my-2">Update Image1 <small>(recommended 100 px)</small></div>

                        <input
                            type="file"
                            class="form-control"
                            title="image1"
                            @change="updateImage1"
                            accept="image/*"
                        />
                    </div>
                    <div class="col-6">
                        <div class="my-2">Update Image2 <small>(recommended 100 px)</small></div>

                        <input
                            type="file"
                            class="form-control"
                            title="image2"
                            @change="updateImage2"
                            accept="image/*"
                        />
                    </div>
                    <div class="col-6">
                        <div class="my-2">Update Image3 <small>(recommended 100 px)</small></div>

                        <input
                            type="file"
                            class="form-control"
                            title="image3"
                            @change="updateImage3"
                            accept="image/*"
                        />
                    </div>
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ creatingReview ? "Please wait..." : "Create" }}
                </Button>
            </form>
        </Card>
        <Card>
            <h1 class="font-semibold mb-3">Reviews</h1>
            <div class="grid grid-cols-3 bg-green-200 p-2">
                <div>Id</div>
                <div>Title</div>
                <div>Action</div>
            </div>
            <div class="p-4 text-center text-sm" v-if="reviews.length <= 0 && initialized">
                No videos Found
            </div>
            <div class="grid grid-cols-3 p-2" v-for="review of reviews" :key="review._id">
                <div>{{ review._id }}</div>
                <div>{{ review.title }}</div>

                <div>
                    <router-link :to="{ name: 'review-edit', params: { id: review._id } }">
                        <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
                    </router-link>
                    <i
                        class="cursor-pointer text-red-500 fas fa-trash"
                        @click="deleteReview(review._id)"
                    />
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "../../components/UI/Card.vue"
import Button from "../../components/UI/Button.vue"
import { http } from "../../utils/http"
import HtmlEditor from "../../components/other/HtmlEditor.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            users: [],
            videos: [],
            reviews: [],
            videoId: "",
            userId: "",
            title: "",
            description: "",
            image1: "",
            image2: "",
            image3: "",

            showForm: false,
            initialized: false,
            creatingReview: false,
        }
    },
    mounted() {
        this.loadVideos()
        this.loadreviews()
    },
    methods: {
        updateImage1(e) {
            this.image1 = e.target.files[0]
        },
        updateImage2(e) {
            this.image2 = e.target.files[0]
        },
        updateImage3(e) {
            this.image3 = e.target.files[0]
        },
        async loadVideos() {
            try {
                const res = await http.get("/videos")
                const res2 = await http.get("/users")
                this.videos = res.data.result
                this.users = res2.data.result
            } catch (err) {
                console.log("error in loading videos", err)
            }
            this.initialized = true
        },
        async loadreviews() {
            try {
                const res = await http.get("/reviews")
                this.reviews = res.data.result
            } catch (err) {
                console.log("error in loading reviews", err)
            }
            this.initialized = true
        },

        async createReview() {
            this.creatingReview = true
            try {
                const formData = new FormData()
                formData.append("videoId", this.videoId || "")
                formData.append("userId", this.userId || "")
                formData.append("title", this.title || "")
                formData.append("description", this.description || "")
                formData.append("image1", this.image1 || "")
                formData.append("image2", this.image2 || "")
                formData.append("image3", this.image3 || "")

                console.log("formData", formData)

                const createdRes = await http.post("/reviews", formData)
                const createdcategory = createdRes.data.result
                this.reviews.push(createdcategory)
                this.title = ""
                this.description = ""
                this.image1 = ""

                this.$toast.success("Review Added Successfully")
                window.location.href = "/reviews"
            } catch (err) {
                console.log("error in creating reviews", err)
                this.$toast.error("Some error occured")
            }

            this.creatingReview = false
        },
        async deleteReview(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete("/reviews/" + id)
                    this.$toast.success("Review Deleted Successfully")
                    this.reviews = this.reviews.filter((item) => item.id !== id)
                    window.location.href = "/reviews"
                }
            } catch (err) {
                console.log("error in creating video", err)
                this.$toast.error("Some error occured")
            }
        },
    },
}
</script>

<style></style>
