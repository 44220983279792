<template>
    <div>
        <div class="mb-3">
            <h4 class="h6"><strong>Filter</strong></h4>
            <div class="bg-white p-3">
                <div class="row">
                    <div class="col-md-4">
                        <div class="my-2">Select Subject</div>
                        <div>
                            <SelectDropdown
                                :id="`form-subject`"
                                :options="subjects"
                                :value="selectedSubject"
                                @change="selectedSubject = $event"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="noResults" class="my-3">No Results</div>
            </div>
        </div>
        <CrudIndex
            v-if="!loading"
            title="Videos"
            :viewColumns="columns"
            fetchUrl="/videos?populate_subjects=true"
            createUrl="/videos"
            updateRootUrl="/videos"
            deleteRootUrl="/videos"
            :createFields="createFields"
            editRouteName="video-edit"
            :dataItemFilter="dataItemFilter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            subjects: [],
            selectedSubject: "",
            loading: true,
            createFields: [],
            courseCollections: [],
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Video Title", "title", "string"),
                createColumn("Subject", "subjectId.title", "string"),
                createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectDropdown },
    async mounted() {
        this.subjects = await this.fetchSubjects()
        await this.loadCourseCollections()
        this.createFields = [
            createField("Video Title*", "title", "text", true),
            createField(
                "Subject*",
                "subjectId",
                "select",
                true,
                "",
                this.subjects.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField(
                "Course Collection*",
                "courseCollectionId",
                "select",
                true,
                "",
                this.courseCollections.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField("Description", "description", "textarea", false),
            createField("View Thumbnail", "thumbnail", "image", false),
            createField("Select/update Video", "videoUrl", "file", false),
            createField("Status", "status", "switch", false, true),
        ]
        this.loading = false
    },
    methods: {
        async fetchSubjects() {
            const res = await http.get("/subjects")
            return res.data.result
        },
        async loadCourseCollections() {
            try {
                const res = await http.get("/course-collections")
                this.courseCollections = res.data.result
            } catch (err) {
                console.log("error in loading subjects", err)
            }
        },
        dataItemFilter(items) {
            console.log("old items", items)
            if (this.selectedSubject) {
                items = items.filter((item) => item.subjectId._id == this.selectedSubject)
            }
            if (!items.length) {
                this.noResults = true
            } else {
                this.noResults = false
            }
            return items
        },
    },
}
</script>

<style></style>
