<template>
    <div>
        <CrudIndex
            title="Discussion"
            :viewColumns="columns"
            :fetchUrl="`/discussions?video_id=${$route.params.id}`"
            createUrl="/discussions"
            updateRootUrl="/discussions"
            deleteRootUrl="/discussions"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "../../../components/crud/index.vue"
import { createField, createColumn } from "../../../utils/common"

export default {
    data() {
        return {
            loading: false,
            createFields: [],
            columns: [
                createColumn("User", "userId", "number"),
                createColumn("Title", "title", "string"),
                // createColumn("Description", "description", "string"),
            ],
        }
    },
    async mounted() {
        this.loading = true
        try {
            const res = await http.get("/users")
            this.users = res.data.result
            this.createFields = [
                createField("User*", "userId", "select", true, null, [
                    ...this.users.map((item) => ({
                        _id: item._id,
                        title: `${item.firstName} ${item.lastName} (${item._id.slice(-5)})`,
                    })),
                ]),
                createField("Title*", "title", "text", true),
                createField("Description", "description", "textarea", false),
                createField("Add Image", "imageUrl", "image", false),
                createField("Video Id", "videoId", "hidden", true, this.$route.params.id),
            ]
        } catch (error) {
            console.log("error in loading users", error)
        }
        this.loading = false
    },
    components: { CrudIndex },
}
</script>

<style></style>
