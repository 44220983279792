<template>
  <div>
    <div v-if="show" @click="$emit('hide')" class="modal-backdrop"></div>
    <div
      v-if="show"
      class="custom-confirm-modal"
      :style="`max-width: ${maxWidth}px;`"
    >
      <div class="modal-header">
        <slot name="header" />
      </div>
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 500 },
  },
};
</script>

<style lang="scss" scoped>
.custom-confirm-modal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 15px;
  max-height: 80%;
  overflow-y: scroll;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
  z-index: 500;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
