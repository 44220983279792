<template>
    <div class="recorder-container">
        <!-- <button
      @click="sendRecording"
      :disabled="!isRecording || !recordedChunks.length"
    >
      Send Recording
    </button> -->
        <audio
            style="flex: 1"
            v-if="!isRecording && recordedChunks.length > 0"
            :src="audioURL"
            controls
        ></audio>
        <button class="record-btn" @click="toggleRecording">
            <span v-if="isRecording"> <i class="fas fa-stop-circle" /></span
            ><span v-else>
                <i class="fas fa-microphone" />
            </span>
        </button>
        <button class="record-btn" @click="clearRecording" v-if="recordedChunks.length > 0">
            <span>
                <i class="fas fa-times" />
            </span>
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isRecording: false,
            recordedChunks: [],
            mediaRecorder: null,
            audioURL: "",
        }
    },
    methods: {
        clearRecording() {
            // Reset recordedChunks array and audioURL
            this.recordedChunks = []
            this.audioURL = ""
            this.$emit("audioBlob", this.audioBlob)
        },
        toggleRecording() {
            if (!this.isRecording) {
                // Reset recordedChunks array
                this.recordedChunks = []

                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then((stream) => {
                        this.mediaRecorder = new MediaRecorder(stream)
                        this.mediaRecorder.ondataavailable = (event) => {
                            if (event.data.size > 0) {
                                this.recordedChunks.push(event.data)
                            }
                        }
                        this.mediaRecorder.onstop = () => {
                            const blob = new Blob(this.recordedChunks, {
                                type: "audio/ogg; codecs=opus",
                            })
                            this.audioURL = window.URL.createObjectURL(blob)
                            this.sendRecording()
                        }
                        this.mediaRecorder.start()
                        this.isRecording = true
                    })
                    .catch((error) => {
                        console.error("Error accessing user media:", error)
                        this.$toast.error("Please allow permission for mic!")
                        this.errorMessage = "Please allow Audio Recording Permission"
                    })
            } else {
                this.mediaRecorder.stop()
                this.sendRecording()
                this.isRecording = false
            }
        },
        sendRecording() {
            console.log("send recording")
            if (this.recordedChunks.length > 0) {
                const formData = new FormData()
                const audioBlob = new Blob(this.recordedChunks, {
                    type: "audio/ogg; codecs=opus",
                })
                console.log("emit audio blob", audioBlob)
                formData.append("audio", audioBlob, "recording.ogg")
                this.$emit("audioBlob", audioBlob)
                // fetch("/upload-audio", {
                //   method: "POST",
                //   body: formData,
                // })
                //   .then((response) => {
                //     if (response.ok) {
                //       alert("Audio recording submitted successfully!");
                //     } else {
                //       alert("Error submitting audio recording");
                //     }
                //   })
                //   .catch((error) => {
                //     console.error("Error submitting audio recording:", error);
                //   });
            }
        },
    },
}
</script>

<style scoped lang="scss">
.record-btn {
    border-radius: 10px;
    border: none;
    background-color: red;
    color: white;
    width: 30px;
}
.recorder-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}
/* Add your component's styles here */
</style>
