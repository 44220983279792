<template>
    <div>Coming Soon.</div>
</template>

<script>
import { http, baseURL } from "@/utils/http"
import Paginator from "@/components/other/Paginator"
import SelectDropdown from "@/components/crud/SelectDropdown"
import Button from "@/components/UI/Button"
import { durify } from "@/utils/common"

export default {
    components: { Paginator, SelectDropdown, Button },
    data() {
        return {
            videoReportProgresses: [],
            loading: false,
            page: 1,
            limit: 50,
            totalResults: 0,
            totalPages: 0,
            subjects: [],
            videos: [],
            selectedSubjectId: "",
            selectedVideoId: "",
            processing: false,
        }
    },
    async mounted() {
        this.fetchSubjects()
        await this.fetchResults()
    },
    methods: {
        durify,
        getFormattedUser(user) {
            return user.apiUserDataJson && JSON.parse(user.apiUserDataJson)
        },
        async fetchSubjects() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/subjects`)
                this.subjects = res.data.result
            } catch (err) {
                console.log("error in fetching subjects", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchVideos() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/videos/subject/${this.selectedSubjectId}`)
                this.videos = res.data.result
            } catch (err) {
                console.log("error in fetching videos", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchResults(clearFilters = false, downloadExcel = false) {
            if (clearFilters) {
                this.selectedVideoId = ""
                this.selectedSubjectId = ""
            }
            if (downloadExcel) {
                return window.open(
                    baseURL +
                        `/reports/videos?page=${this.page}&limit=${this.limit}&videoId=${this.selectedVideoId}&subjectId=${this.selectedSubjectId}&excel=${downloadExcel}`
                )
            }
            this.totalResults = 0
            this.totalPages = 0
            this.loading = true
            try {
                const res = await http.get(
                    `/reports/videos?page=${this.page}&limit=${this.limit}&videoId=${this.selectedVideoId}&subjectId=${this.selectedSubjectId}`
                )
                this.videoReportProgresses = res.data.result
                this.totalResults = res.data.totalResults
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("error in loading video report", err)
                this.$toast.error("Error occured")
            }
            this.loading = false
        },
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchResults()
            }
        },
    },
    watch: {
        selectedSubjectId(e) {
            if (e) {
                this.fetchVideos()
            }
        },
    },
}
</script>

<style></style>
