<template>
    <div>
        <CrudIndex
            title="Quiz Question"
            :viewColumns="columns"
            :fetchUrl="`/quiz-questions-selected?populate=true&quiz_id=${quizId}`"
            createUrl="/quiz-questions-selected"
            updateRootUrl="/quiz-questions-selected"
            deleteRootUrl="/quiz-questions-selected"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            createFields: [],
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Quiz Id", "quizId._id", "string"),
                createColumn("Question", "quizQuestionMasterId.title", "string"),
            ],
            questions: [],
            quizzes: [],
        }
    },
    props: { quizId: { type: String, default: "" } },
    components: { CrudIndex },
    async mounted() {
        await this.fetchQuestions()
        if (!this.quizId) {
            await this.fetchQuiz()
            this.createFields = [
                createField(
                    "Quiz*",
                    "quizId",
                    "select",
                    true,
                    "",
                    this.quizzes.map((item) => ({
                        _id: item._id,
                        title: item.title,
                    }))
                ),
            ]
        }
        this.createFields = this.createFields.concat([
            createField(
                "Question*",
                "quizQuestionMasterId",
                "select",
                true,
                "",
                this.questions.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
        ])
        if (this.quizId) {
            this.createFields = this.createFields.concat([
                createField("quizId", "quizId", "hidden", true, this.quizId),
            ])
        }
        this.loading = false
    },
    methods: {
        async fetchQuestions() {
            // fetch only according to subjects....
            const res = await http.get(`/quiz-questions?subject_restriction_quiz_id=${this.quizId}`)
            this.questions = res.data.result
        },
        async fetchQuiz() {
            const res = await http.get("/quiz")
            this.quizzes = res.data.result
        },
        editingFormatter(item) {
            return {
                ...item,
                quizId: item.quizId._id,
                quizQuestionMasterId: item.quizQuestionMasterId._id,
            }
        },
    },
}
</script>

<style></style>
