<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Quiz"
            :viewColumns="columns"
            fetchUrl="/quiz"
            updateRootUrl="/quiz"
            createUrl="/quiz"
            deleteRootUrl="/quiz"
            :createFields="createFields"
            :onlyEdit="true"
            :onlyEditData="quizData"
            :allowGoBack="true"
            :backRoute="`/videos/${quizData.videoId}/quizzes`"
        />

        <div class="my-4">
            <h3 class="h5">Questions In This Quiz</h3>
            <hr />
        </div>
        <SelectedQuestionIndex :quizId="$route.params.id" />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import SelectedQuestionIndex from "@/components/SelectedQuestionIndex.vue"
import { createField, createColumn } from "@/utils/common"

export default {
    data() {
        return {
            loading: false,
            quizData: null,
            videos: [],
            createFields: [],
            columns: [
                createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectedQuestionIndex },
    async mounted() {
        this.loading = true
        try {
            await this.fetchVideos()
            await this.fetchQuizData()
        } catch (error) {
            console.log("error in fetching data for edit", error)
        }

        this.createFields = [
            createField(
                "Video*",
                "videoId",
                "select",
                true,
                "",
                this.videos.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField("Title*", "title", "text", true),
            createField(
                `Video Time In Seconds To Trigger`,
                "videoTimeInSecondsToTrigger",
                "timebar|0|1200",
                true
            ),
            createField("Description", "description", "textarea", false),
            createField("Status", "status", "switch", false, true),
        ]
        this.loading = false
    },
    methods: {
        async fetchVideos() {
            const res = await http.get("/videos")
            this.videos = res.data.result
        },
        async fetchQuizData() {
            const res = await http.get("/quiz/" + this.$route.params.id)
            this.quizData = res.data.result
        },
    },
}
</script>

<style></style>
