<template>
    <div>
        <div class="my-2">Analytic reports</div>
        <div v-if="loading" class="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary"></div>
        </div>
        <div>
            <table
                v-if="!loading"
                class="table-responsive table table-light table-sm table-hover table-striped"
                style="font-size: 0.8em"
            >
                <thead>
                    <tr>
                        <td>Student Name</td>
                        <td>Subject</td>
                        <td>Video Name</td>
                        <td>Duration</td>
                        <td>Total Quiz</td>
                        <td>Total Questions</td>
                        <td>Correct Answer</td>
                        <td>Incorrect Answer</td>
                        <td>Not Attempted</td>
                        <td>Score of User</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="studentReport of studentReports" :key="studentReport.student">
                        <td>
                            {{ studentReport.student }}
                        </td>
                        <td>
                            {{ studentReport.subject }}
                        </td>
                        <td>
                            {{ studentReport.video }}
                        </td>
                        <td>
                            {{ studentReport.duration }}
                        </td>
                        <td>
                            {{ studentReport.totalQuiz }}
                        </td>
                        <td>
                            {{ studentReport.totalQuestions }}
                        </td>
                        <td>
                            {{ studentReport.totalCorrectAnswers }}
                        </td>
                        <td>
                            {{ studentReport.totalInCorrectAnswers }}
                        </td>
                        <td>
                            {{ studentReport.totalNotAttempted }}
                        </td>
                        <td>
                            {{ studentReport.totalScore }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import Paginator from "@/components/other/Paginator"

export default {
    components: { Paginator },
    data() {
        return {
            studentReports: [],
            loading: false,
            page: 1,
            limit: 2,
            totalResults: 0,
            totalPages: 0,
        }
    },
    async mounted() {
        await this.fetchStudentReports()
    },
    methods: {
        async fetchStudentReports() {
            this.loading = true
            try {
                const res = await http.get(`/reports?page=${this.page}&limit=${this.limit}`)
                this.studentReports = res.data.result
                this.totalResults = res.data.totalResults
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("erroro in loading report", err)
                this.$toast.error("Error occured")
            }
            this.loading = false
        },
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchStudentReports()
            }
        },
    },
}
</script>

<style></style>
