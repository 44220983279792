<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Chapter"
            :viewColumns="columns"
            :fetchUrl="`/video-chapters?video_id=${$route.params.id}`"
            createUrl="/video-chapters"
            updateRootUrl="/video-chapters"
            deleteRootUrl="/video-chapters"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../../components/crud/index.vue"
import { createField, createColumn } from "../../../utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            loading: true,
            createFields: [],
            columns: [
                createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Start Time", "formattedStartTimeInSeconds", "number"),
                createColumn("End Time", "formattedEndTimeInSeconds", "number"),
            ],
        }
    },
    components: { CrudIndex },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true
            const video = await this.fetchVideo(this.$route.params.id)
            this.createFields = [
                createField("Title*", "title", "text", true),
                createField(
                    `Start Time In Mins*`,
                    "startTimeInSeconds",
                    "timebar|0|" + video.videoDurationInSeconds,
                    true
                ),
                createField(
                    `End Time In Mins*`,
                    "endTimeInSeconds",
                    "timebar|0|" + video.videoDurationInSeconds,
                    true
                ),
                createField("Video Id", "videoId", "hidden", true, this.$route.params.id),
            ]
            this.loading = false
        },
        async fetchVideo(id) {
            const res = await http.get("/videos/" + id)
            return res.data.result
        },
    },
}
</script>

<style></style>
