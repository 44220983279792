<template>
    <div>
        <div class="mb-3">
            <h4 class="h6"><strong>Filter</strong></h4>
            <div class="bg-white p-3">
                <form>
                    <div class="row">
                        <div class="col-md-4" v-if="states.length">
                            <div class="my-2">Filter By States</div>
                            <div>
                                <SelectDropdown
                                    :id="`form-subject`"
                                    :options="
                                        states.map((item) => ({
                                            _id: item._id,
                                            title: item.name,
                                        }))
                                    "
                                    :value="selectedStates"
                                    :multiple="true"
                                    @change="selectedStates = $event"
                                />
                                <input
                                    name="states"
                                    type="hidden"
                                    :value="selectedStates.filter((item) => !!item).join(',')"
                                />
                            </div>
                            <Button type="button" @click="clearFilters" class="me-2"
                                >Clear Filters</Button
                            >
                            <Button>Search Results</Button>
                        </div>
                    </div>
                </form>
                <div v-if="noResults" class="my-3">No Results</div>
            </div>
        </div>
        <CrudIndex
            v-if="!loading"
            title="Certificates"
            :viewColumns="columns"
            :fetchUrl="`/certificate-masters?states=${$route.query.states || ''}`"
            createUrl="/certificate-masters"
            updateRootUrl="/certificate-masters"
            createRouteName="certificate-create"
            editRouteName="certificate-edit"
            deleteRootUrl="/certificate-masters"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"
import { mapState } from "vuex"
import Button from "../../components/UI/Button.vue"

export default {
    data() {
        return {
            subjects: [],
            courseCollections: [],
            searchField: "",
            selectedStates: [],
            loading: true,
            createFields: [],
            noResults: false,
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Course Colletion", "courseCollectionId.title", "string"),
                createColumn("States", "states", "string"),
                createColumn("Created At", "createdAt", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectDropdown, Button },
    computed: {
        ...mapState("misc", ["states"]),
    },
    async mounted() {
        this.selectedStates = (this.$route.query.states || "").split(",")
        this.courseCollections = await this.fetchCourseCollections()
        this.createFields = [
            createField("Title*", "title", "text", true),
            createField("Show Name", "showName", "switch", false, true),
            createField("Name X Coordinate", "namePositionX", "number", false),
            createField("Name Y Coordinate", "namePositionY", "number", false),
            createField("Show Program Name", "showProgramName", "switch", false, true),
            createField("Program Name X Coordinate", "programNamePositionX", "number", false),
            createField("Program Name Y Coordinate", "programNamePositionY", "number", false),
            createField("Show Date", "showDate", "switch", false, true),
            createField("Date X Coordinate", "datePositionX", "number", false),
            createField("Date Y Coordinate", "datePositionY", "number", false),
            createField("Background Image", "backgroundImage", "image", false),
            createField(
                "State",
                "stateId",
                "select",
                true,
                "",
                this.states.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
            createField(
                "Course Collection",
                "courseCollectionId",
                "select",
                true,
                "",
                this.courseCollections.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        clearFilters() {
            window.location.href = "/certificates"
        },
        async fetchCourseCollections() {
            const res = await http.get(`/course-collections`)
            return res.data.result
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
    },
}
</script>

<style></style>
