<template>
    <QuizIndex />
</template>

<script>
import QuizIndex from "@/components/QuizIndex.vue"

export default {
    data() {
        return {}
    },
    components: { QuizIndex },
    async mounted() {},
    methods: {},
}
</script>

<style></style>
