<template>
    <div>
        <CrudIndex
            title="State"
            :viewColumns="columns"
            fetchUrl="/states"
            createUrl="/states"
            updateRootUrl="/states"
            deleteRootUrl="/states"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Abbr*", "abbreviation", "text", true),
            ],
            columns: [
                createColumn("Id", "_id", "string"),
                createColumn("Name", "name", "string"),
                createColumn("Abbreviation", "abbreviation", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
