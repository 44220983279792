<template>
    <section class="message-area">
        <div class="container">
            <Button @click="$router.push('/events')"
                ><i class="fas fa-chevron-left"></i> Back To Events</Button
            >
            <div class="row">
                <div class="col-12">
                    <div class="col-md-12 mb-3">
                        <div style="max-width: 210px">
                            <AppBtn
                                @click="$router.push('/profile/events')"
                                title="View All Events"
                                icon="chevron-left"
                            />
                        </div>
                    </div>
                    <div class="chat-area">
                        <div class="chatbox">
                            <div class="modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="msg-head">
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="d-flex align-items-center">
                                                    <span class="chat-icon"
                                                        ><img
                                                            class="img-fluid"
                                                            src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                                            alt="image title"
                                                    /></span>
                                                    <div class="flex-shrink-0">
                                                        <img
                                                            class="img-fluid"
                                                            src="https://mehedihtml.com/chatbox/assets/img/user.png"
                                                            alt="user img"
                                                        />
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h3>
                                                            {{ event?.speaker?.firstName }}
                                                            {{ event?.speaker?.lastName }}
                                                        </h3>
                                                        <p>Admin/Speaker</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <ul class="moreoption">
                                                    <li class="navbar nav-item dropdown">
                                                        <a
                                                            class="nav-link dropdown-toggle"
                                                            href="#"
                                                            role="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            ><i
                                                                class="fa fa-ellipsis-v"
                                                                aria-hidden="true"
                                                            ></i
                                                        ></a>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item" href="#"
                                                                    >Silent Mode</a
                                                                >
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="#"
                                                                    >On Break</a
                                                                >
                                                            </li>
                                                            <li>
                                                                <hr class="dropdown-divider" />
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="#"
                                                                    >Close Event</a
                                                                >
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="modal-body"
                                        ref="chatContainerWindow"
                                        id="chat-container-window"
                                    >
                                        <div class="msg-body">
                                            <ul>
                                                <!-- <li class="sender">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                              <img
                                class="img-fluid"
                                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                                alt="user img"
                              />
                            </div>
                            <div class="ml-2">
                              <p>Hey, Are you there?</p>
                              <span class="time">10:06 am</span>
                            </div>
                          </div>
                        </li>

                        <li class="sender">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                              <img
                                class="img-fluid"
                                src="https://mehedihtml.com/chatbox/assets/img/user.png"
                                alt="user img"
                              />
                            </div>
                            <div class="ml-2">
                              <p>Are you working?</p>
                              <span class="time">10:06 am</span>
                            </div>
                          </div>
                        </li> -->

                                                <!-- <li class="replier">
                          <p>yes... on it</p>
                          <span class="time">junt now</span>
                        </li> -->
                                                <li
                                                    v-for="message in messages"
                                                    :class="{
                                                        'message-box': true,
                                                        replier: isReplier(message),
                                                        sender: isSender(message),
                                                    }"
                                                    :key="message._id"
                                                >
                                                    <span class="time">
                                                        {{ getUser(message?.senderId).firstName }}
                                                        {{
                                                            getUser(message?.senderId).lastName
                                                        }}</span
                                                    >
                                                    <div
                                                        :class="[
                                                            'attachment-group',
                                                            {
                                                                replier: isReplier(message),
                                                            },
                                                            {
                                                                sender: isSender(message),
                                                            },
                                                        ]"
                                                    >
                                                        <audio
                                                            style="flex: 1"
                                                            v-if="message?.audioSrc"
                                                            :src="
                                                                serverImgUrl + message?.audioSrc.url
                                                            "
                                                            controls
                                                        ></audio>
                                                        <div v-if="message?.fileSrc">
                                                            File Attachement:
                                                        </div>
                                                        <a
                                                            class="file-attachment"
                                                            style="flex: 1"
                                                            v-if="message?.fileSrc"
                                                            :href="
                                                                serverImgUrl + message?.fileSrc.url
                                                            "
                                                            target="_blank"
                                                            >{{ message?.fileSrc.name }}</a
                                                        >
                                                    </div>
                                                    <p>{{ message?.text }}</p>
                                                    <span class="time">{{
                                                        new Date(message.createdAt).toLocaleString()
                                                    }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="send-box">
                                        <div
                                            class="d-flex justify-content-end align-items-center"
                                            style="gap: 10px"
                                        >
                                            <AudioRecorder
                                                ref="audioRecorder"
                                                @audioBlob="handleAudioBlob"
                                            />
                                            <div class="bg-secondary text-white pt-1 px-3 mb-1">
                                                <span
                                                    class="label"
                                                    @click="$refs.inputFile.click()"
                                                    style="cursor: pointer"
                                                >
                                                    <imgf
                                                        class="img-fluid"
                                                        src="https://mehedihtml.com/chatbox/assets/img/upload.svg"
                                                        alt="image title"
                                                    />
                                                    Select File
                                                </span>
                                                <input
                                                    @change="handleFileSelection"
                                                    type="file"
                                                    ref="inputFile"
                                                    name="upload"
                                                    placeholder="Upload File"
                                                    aria-label="Upload File"
                                                    style="display: none"
                                                />
                                            </div>
                                        </div>

                                        <div v-if="file">
                                            <i class="fas fa-file" /> Selected File:
                                            {{ selectedFileName }}
                                        </div>

                                        <form @submit.prevent="sendMessage">
                                            <input
                                                type="text"
                                                class="form-control"
                                                aria-label="message…"
                                                placeholder="Write message…"
                                                v-model="text"
                                            />
                                            <button type="submit" v-bind:disabled="sendingMessage">
                                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                {{ sendingMessage ? "Please wait..." : "Send" }}
                                            </button>
                                        </form>
                                        <div class="send-btns">
                                            <div class="attach">
                                                <!-- <div class="button-wrapper">
                          <span class="label">
                            <img
                              class="img-fluid"
                              src="https://mehedihtml.com/chatbox/assets/img/upload.svg"
                              alt="image title"
                            />
                            Record Audio </span
                          ><input
                            type="file"
                            name="upload"
                            id="upload"
                            class="upload-box"
                            placeholder="Upload File"
                            aria-label="Upload File"
                          />
                        </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex"
import { http, serverImgUrl } from "../../utils/http"
import io from "socket.io-client"
import Button from "../../components/UI/Button.vue"
import AudioRecorder from "./audio-recorder.vue"

export default {
    data() {
        return {
            audioBlob: null,
            event: null,
            messages: [],
            socket: null,
            text: "",
            file: null,
            selectedFileName: "",
            serverImgUrl,
            sendingMessage: false,
        }
    },
    components: {
        Button,
        AudioRecorder,
    },
    methods: {
        isSender(message) {
            return message.senderId?._id !== this.user._id
        },
        isReplier(message) {
            return message.new || message.senderId?._id === this.user._id
            return message.new || message.senderId?._id === user._id
        },
        handleAudioBlob(event) {
            this.audioBlob = event
        },
        scrollChat() {
            setTimeout(() => {
                const scrollHeight =
                    this.$refs.chatContainerWindow && this.$refs.chatContainerWindow.scrollHeight
                this.$refs.chatContainerWindow.scroll({
                    top: scrollHeight,
                    behavior: "smooth",
                })
            }, 100)
        },
        async fetchEventDetails() {
            const response = await http.get(`/events/${this.$route.params.id}`)
            const {
                data: { result },
            } = response
            this.event = result
            this.messages = [...result?.messages]
            this.scrollChat()
            // console.log(
            //   `🚀 ~ file: _id.vue:231 ~ fetchEventDetails ~ this.messages:`,
            //   this.messages
            // );
        },
        getUser(userId) {
            const userData = userId?.apiUserDataJson ? JSON.parse(userId?.apiUserDataJson) : {}
            return userData
        },
        async sendMessage(eve) {
            if (!this.text) {
                return this.$toast.error("Message is required!")
            }
            if (!this.text && this.file) return
            this.sendingMessage = true

            // Form handling.
            const form = new FormData()
            form.append("text", this.text)
            form.append("file", this.file)
            if (this.audioBlob) {
                form.append("audio", this.audioBlob, "recording.ogg")
            }
            console.table({ text: this.text, file: this.file })

            try {
                const response = await http.post(`/events/${this.$route.params.id}/message`, form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                console.log(`🚀 ~ file: _id.vue:263 ~ sendMessage ~ response:`, response)

                if (response.status === 200) {
                    this.text = ""
                    this.file = null
                    this.selectedFileName = ""
                    this.socket.emit("message", response.data?.message)
                    this.messages.push({ new: true, ...response.data?.message })
                    this.sendingMessage = false
                    this.$refs.audioRecorder.clearRecording()
                }
            } catch (error) {
                console.log(`🚀 ~ file: _id.vue:261 ~ sendMessage ~ error:`, error)
            }

            this.scrollChat()
        },

        handleFileSelection(event) {
            this.file = event.target.files[0]
            this.selectedFileName = this.file?.name
        },
    },
    async mounted() {
        console.table({ text: this.text, file: this.file })
        await this.fetchEventDetails()
        this.socket = io(serverImgUrl)

        this.socket.emit("setup event", this.$route.params.id)

        // Join event in socket.
        this.socket.emit("join event", this.$route.params.id)

        this.socket.on("message-received", (message) => {
            console.log(`🚀 ~ file: _id.vue:280 ~ this.socket.on ~ message:recieved`, message)
            console.log(`🚀 ~ file: _id.vue:321 ~ this.socket.on ~ messages:`, this.messages)
            delete message.new
            this.messages.push(message)
        })
    },
    computed: {
        ...mapState("auth", ["user"]),
    },
}
</script>

<style scoped lang="scss">
.message-area {
    height: 100vh;
    overflow: hidden;
    padding: 30px 0;
    background: #f5f5f5;
}

.chat-area {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 0.3rem;
    height: 80vh;
    overflow: hidden;
    min-height: calc(100% - 1rem);
}

.chatlist {
    outline: 0;
    height: 100%;
    overflow: hidden;
    width: 300px;
    padding: 15px;
}

.chat-area .modal-content {
    border: none;
    border-radius: 0;
    outline: 0;
    height: 100%;
}

.chat-area .modal-dialog-scrollable {
    height: 100% !important;
}

.chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
    border-left: 1px solid #ccc;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-area .form-control {
    display: block;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}

a.add img {
    height: 36px;
}

.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
}

.chat-list h3 {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.chat-list a.d-flex {
    margin-bottom: 15px;
    position: relative;
    text-decoration: none;
}

.chat-list .active {
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 34px;
    height: 12px;
    width: 12px;
    background: #00db75;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head h3 {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}

.msg-head p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.msg-head {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.moreoption {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 16px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}

.msg-body ul {
    overflow: hidden;
}

.msg-body ul li {
    list-style: none;
    margin: 15px 0;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
}

.msg-body ul li.sender:before {
    /* display: block;
  clear: both;
  content: "";
  position: absolute;
  top: -6px;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #f5f5f5 transparent;
  -webkit-transform: rotate(-37deg);
  -ms-transform: rotate(-37deg);
  transform: rotate(-37deg); */
}

.msg-body ul li.sender p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: #f5f5f5;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
}

.msg-body ul li.sender p b {
    display: block;
    color: #180660;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replier {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}

.msg-body ul li.replier:before {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    bottom: 15px;
    right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #4b7bec transparent;
    -webkit-transform: rotate(37deg);
    -ms-transform: rotate(37deg);
    transform: rotate(37deg);
}

.msg-body ul li.replier p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: #4b7bec;
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 0;
}

.msg-body ul li.replier p b {
    display: block;
    color: #061061;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.replier:after {
    display: block;
    content: "";
    clear: both;
}

.time {
    display: block;
    color: #000;
    font-size: 8px;
    line-height: 1.5;
    font-weight: 400;
}

li.replier .time {
    margin-right: 20px;
}

.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
}

.divider:after {
    display: block;
    content: "";
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #ebebeb;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.send-box {
    padding: 15px;
    border-top: 1px solid #ccc;
}

.send-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.send-box .form-control {
    display: block;
    width: 85%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.send-box button {
    border: none;
    background: #3867d6;
    padding: 0.375rem 5px;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 14px;
    font-weight: 400;
    width: 24%;
    margin-left: 1%;
}

.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    /* position: relative; */
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    background: #f6f7fa;
    border-radius: 3px;
    padding: 5px 15px;
    margin-right: 5px;
    margin-bottom: 5px;
    /* overflow: hidden; */
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 13px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach {
    display: flex;
    width: 100%;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #f6f7fa;
    background-clip: padding-box;
    border: 1px solid #f6f7fa;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #f6f7fa;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}

@media (max-width: 767px) {
    .chat-icon {
        display: block;
        margin-right: 5px;
    }
    .chatlist {
        width: 100%;
    }
    .chatbox {
        width: 100%;
        position: absolute;
        left: 1000px;
        right: 0;
        background: #fff;
        transition: all 0.5s ease;
        border-left: none;
    }
    .showbox {
        left: 0 !important;
        transition: all 0.5s ease;
    }
    .msg-head h3 {
        font-size: 14px;
    }
    .msg-head p {
        font-size: 12px;
    }
    .msg-head .flex-shrink-0 img {
        height: 30px;
    }
    .send-box button {
        width: 28%;
    }
    .send-box .form-control {
        width: 70%;
    }
    .chat-list h3 {
        font-size: 14px;
    }
    .chat-list p {
        font-size: 12px;
    }
    .msg-body ul li.sender p {
        font-size: 13px;
        padding: 8px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .msg-body ul li.replier p {
        font-size: 13px;
        padding: 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}
.file-attachment {
    background-color: #a4a2a2;
    color: white;
    padding: 15px;
    display: inline-block;
    margin-bottom: 3px;
}
// .attachment-group {
//     display: inline-block;
//     &.sender {
//         margin-left: 0;
//     }
//     &.replier {
//         margin-left: 100%;
//     }
// }
</style>
