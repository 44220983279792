<template>
    <div class="bg-white shadow rounded">
        <form @submit.prevent="saveAnswerOptions" class="p-3">
            <div class="row my-3">
                <div class="col-1">Sl. No</div>
                <div class="col-5">Enter Option</div>
                <div class="col-3">Correct Answer</div>
                <div class="col-3">
                    <button
                        class="btn btn-outline-secondary btn-sm"
                        type="button"
                        @click="addOption"
                    >
                        <i class="fas fa-plus"></i> Add Option
                    </button>
                </div>
            </div>
            <div v-if="!answerOptions.length" class="text-sm text-secondary">No Answer Options</div>
            <AnswerOptionItem
                v-for="answerOption of answerOptions"
                :key="answerOption.optionId"
                :id="answerOption.optionId"
                :answerOption="answerOption"
                @update="handleUpdate"
                @remove="removeOption"
            />
            <Button type="submit">{{ processingForm ? "Please wait..." : "Save" }}</Button>
        </form>
    </div>
</template>

<script>
import AnswerOptionItem from "./AnswerOptionItem"
import Button from "../UI/Button.vue"
import { http } from "@/utils/http"

export default {
    data() {
        return { answerOptions: [], processingForm: false, markedAnswerId: "" }
    },
    components: { AnswerOptionItem, Button },
    props: {
        questionId: { type: String, default: "" },
    },
    async mounted() {
        await this.fetchMarkedAnswer()
        await this.loadInitialAnswerOptions()
    },
    methods: {
        async fetchMarkedAnswer() {
            const res = await http.get(`/quiz-answers?question_id=${this.questionId}`)
            if (res.data.result.length) {
                this.markedAnswerId = res.data.result[0].quizAnswerOptionMasterId
            }
        },
        async loadInitialAnswerOptions() {
            try {
                const res = await http.get(
                    `/quiz-answer-options?populate=true&question_id=${this.questionId}`
                )
                console.log("markedAnswerId", this.markedAnswerId)
                this.answerOptions = res.data.result.map((item, i) => ({
                    ...item,
                    optionId: i + 1,
                    isCorrect: this.markedAnswerId === item._id,
                }))
            } catch (err) {
                console.log("error in loading initial answer options", err)
            }
        },
        async saveAnswerOptions() {
            if (this.processingForm) return
            // check if any field is empty or there is no selected answer
            const selectedAnswerExists = this.answerOptions.find((item) => item.isCorrect)
            const emptyAnswerFieldExists = this.answerOptions.find((item) => !item.title)

            if (this.answerOptions.length < 2) {
                return this.$toast.error("Please add at least 2 answer options!")
            }
            if (!selectedAnswerExists) {
                return this.$toast.error("Please select a correct option!")
            }
            if (emptyAnswerFieldExists) {
                return this.$toast.error("Please fillup all fields!")
            }

            this.processingForm = true
            console.log("answer options to save", this.answerOptions)
            try {
                await http.post("/quiz-answers/options/save", {
                    answerOptions: this.answerOptions,
                    questionId: this.questionId,
                })
                this.$toast.success("Saved Successfully")
            } catch (err) {
                this.$toast.error("Error in saving!")
                console.log("error ", err)
            }
            this.processingForm = false
        },
        addOption() {
            this.answerOptions = this.answerOptions.concat({
                // option id is a serial integer id maintained so that simple integer can be used as id even if _id may be
                // present in some cases when data is fetched from database.
                optionId: this.answerOptions.length + 1,
                title: "",
                isCorrect: false,
            })
        },
        handleUpdate({ answerOption, isCorrectSpecified }) {
            this.answerOptions = this.answerOptions.map((item) => {
                if (answerOption.optionId === item.optionId) {
                    // allow only one option to be correct.
                    item.title = answerOption.title

                    if (answerOption.isCorrect && isCorrectSpecified) {
                        item.isCorrect = true
                    }
                } else {
                    if (isCorrectSpecified) {
                        // set all others as false
                        item.isCorrect = false
                    }
                }
                return item
            })
        },
        removeOption(answerOption) {
            this.answerOptions = this.answerOptions.filter(
                (item) => item.optionId != answerOption.optionId
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.add-btn {
    background-color: #777;
    padding: 7px;
    &:hover {
        background-color: #555;
    }
}
</style>
