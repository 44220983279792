<template>
    <div class="page-wrapper" v-if="totalPages > 1">
        <PaginatorItem
            :page="i + 1"
            :key="i"
            :active="page - 1 == i"
            v-for="(key, i) in Array(totalPages)"
            @change="$emit('change', $event)"
        />
        <!-- <PaginatorItem
            :page="i + 1"
            :key="i"
            :active="page - 1 == i"
            v-for="(key, i) in Array(totalPages)
                .fill(0)
                .slice(0, maxPage - 2)"
            @change="$emit('change', $event)"
        />
        <div v-if="totalPages > maxPage" class="m-3">...</div>
        <template v-if="totalPages > maxPage">
            <PaginatorItem
                :page="totalPages - 1"
                :active="page - 1 == totalPages - 1"
                @change="$emit('change', $event)"
            />
            <PaginatorItem
                :page="totalPages"
                :active="page - 1 == totalPages"
                @change="$emit('change', $event)"
            />
        </template> -->
    </div>
</template>

<script>
import PaginatorItem from "./PaginatorItem.vue"
export default {
    emits: ["change"],
    data() {
        return {
            maxPage: 10,
        }
    },
    components: { PaginatorItem },
    props: {
        totalPages: { type: Number, default: () => 0 },
        page: { type: Number, default: () => 1 },
    },
    mounted() {
        if (Number(this.$route.query.page)) {
            this.$emit("change", Number(this.$route.query.page))
        } else {
            this.$emit("change", this.page)
        }
    },
}
</script>

<style lang="scss">
.page-wrapper {
    display: flex;
    flex-wrap: wrap;
}
</style>
