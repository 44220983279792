<template>
    <div>
        <div class="my-2">Subject Stats</div>
        <div>
            <!-- filters -->
            <div class="card card-body my-3">
                <div class="row">
                    <!-- <div class="col-md-12">
                        <DateRangeFilter @change="handleDateChange" />
                    </div> -->
                    <!-- <div class="col-md-4">
                        <div class="my-2">Subject</div>
                        <SelectDropdown
                            v-if="subjects && subjects.length"
                            :id="`form-subject`"
                            :options="
                                subjects.map((item) => ({ _id: item._id, title: item.title }))
                            "
                            :value="selectedSubjectId"
                            @change="selectedSubjectId = $event"
                        />
                    </div> -->
                    <div class="col-md-4" v-if="selectedSubjectId">
                        <div class="my-2">Video</div>
                        <SelectDropdown
                            v-if="videos && videos.length"
                            :id="`form-video-subject`"
                            :options="videos.map((item) => ({ _id: item._id, title: item.title }))"
                            :value="selectedVideoId"
                            @change="selectedVideoId = $event"
                        />
                    </div>
                </div>
                <div>
                    <Button @click="fetchResults(false, true)" class="btn btn-primary bnt-sm me-2">
                        <i class="fas fa-download"></i> Export Excel
                    </Button>
                    <p class="text-secondary">Disabled Status grayed out.</p>
                    <!-- <Button @click="fetchResults(true)" class="btn btn-primary bnt-sm me-2">
                        <i class="fas fa-trash"></i> Clear Filters
                    </Button>
                    <Button @click="fetchResults" class="btn btn-primary bnt-sm me-2">
                        <i class="fas fa-list"></i> Load Results
                    </Button> -->
                </div>
            </div>

            <!-- resutls -->
            <div v-if="loading" class="d-flex justify-content-center my-5">
                <div class="spinner-border text-primary"></div>
            </div>
            <div v-if="totalResults" class="my-3">Total Results: {{ totalResults }}</div>
            <table
                v-if="!loading"
                class="table-responsive table table-light table-sm table-hover table-striped"
                style="font-size: 0.8em"
            >
                <thead>
                    <tr>
                        <td>Sl no</td>
                        <td>Subject</td>
                        <td>Most Watched Video</td>
                        <td>Watch Count</td>
                        <td>Most Solved Quiz</td>
                        <td>Solve Count</td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        :style="`opacity: ${dataItem.Status ? 1 : 0.5}`"
                        v-for="(dataItem, i) of reportData"
                        :key="i"
                    >
                        <td>
                            {{ (page - 1) * limit + (i + 1) }}
                        </td>
                        <td>
                            {{ dataItem["Subject"] }}
                        </td>
                        <td>
                            {{ dataItem["Most Watched Video"] }}
                        </td>
                        <td>
                            {{ dataItem["Watch Count"] }}
                        </td>
                        <td>
                            {{ dataItem["Most Solved Quiz"] }}
                        </td>
                        <td>
                            {{ dataItem["Solve Count"] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
    </div>
</template>

<script>
import { http, baseURL } from "@/utils/http"
import Paginator from "@/components/other/Paginator"
import SelectDropdown from "@/components/crud/SelectDropdown"
import DateRangeFilter from "@/components/UI/DateRangeFilter"
import Button from "@/components/UI/Button"
import { durify } from "@/utils/common"

export default {
    components: { DateRangeFilter, Paginator, SelectDropdown, Button },
    data() {
        return {
            reportData: [],
            loading: false,
            page: 1,
            // don't put limit restrictoin here show all subjects
            limit: 100,
            totalResults: 0,
            totalPages: 0,
            subjects: [],
            videos: [],
            selectedSubjectId: "",
            selectedVideoId: "",
            processing: false,
            startDate: "",
            endDate: "",
            dateInitialized: false,
        }
    },
    async mounted() {
        this.fetchSubjects()
        await this.fetchResults()
    },
    methods: {
        durify,
        // async handleDateChange({ startDate, endDate }) {
        //     this.startDate = startDate
        //     this.endDate = endDate

        //     if (!this.dateInitialized) {
        //         this.dateInitialized = true
        //         await this.fetchResults()
        //     }
        // },
        getFormattedUser(user) {
            return user.apiUserDataJson && JSON.parse(user.apiUserDataJson)
        },
        async fetchSubjects() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/subjects`)
                this.subjects = res.data.result
            } catch (err) {
                console.log("error in fetching subjects", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchVideos() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/videos/subject/${this.selectedSubjectId}`)
                this.videos = res.data.result
            } catch (err) {
                console.log("error in fetching videos", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchResults(clearFilters = false, downloadExcel = false) {
            if (clearFilters) {
                this.selectedVideoId = ""
                this.selectedSubjectId = ""
            }
            const url = `/reports/subject-stats?page=${this.page}&limit=${this.limit}&videoId=${this.selectedVideoId}&subjectId=${this.selectedSubjectId}&start_date=${this.startDate}&end_date=${this.endDate}`
            if (downloadExcel) {
                return window.open(baseURL + url + `&excel=${downloadExcel}`)
            }
            this.totalResults = 0
            this.totalPages = 0
            this.loading = true
            try {
                const res = await http.get(url)
                this.reportData = res.data.result
                this.totalResults = res.data.totalResults
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("error in loading video report", err)
                this.$toast.error("Error occured")
            }
            this.loading = false
        },
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchResults()
            }
        },
    },
    watch: {
        selectedSubjectId(e) {
            if (e) {
                this.fetchVideos()
            }
        },
    },
}
</script>

<style></style>
