<template>
    <div><QuizIndex :videoId="$route.params.id" /></div>
</template>

<script>
import QuizIndex from "@/components/QuizIndex.vue"

export default {
    data() {
        return {}
    },
    components: { QuizIndex },
}
</script>

<style></style>
