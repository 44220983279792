<template>
    <div>
        <div class="text-black font-bold">
            Create User Role
            <span
                @click="showForm = !showForm"
                class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
            >
                <i class="fas fa-minus" v-if="showForm" />
                <i class="fas fa-add" v-else />
            </span>
        </div>
        <Card v-if="showForm">
            <form class="my-3" @submit.prevent="createUserRole">
                <div class="my-2">User Role Name*</div>
                <div>
                    <input required v-model="name" type="text" class="form-control" />
                </div>
                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ creatingUserRole ? "Please wait..." : "Create" }}
                </Button>
            </form>
        </Card>
        <Card>
            <datatable title="User Roles" :columns="columns" :rows="roles">
                <th slot="thead-tr">Actions</th>
                <template slot="tbody-tr" slot-scope="props">
                    <td>
                        <div>
                            <router-link
                                :to="{ name: 'user-role-edit', params: { id: props.row._id } }"
                            >
                                <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
                            </router-link>
                            <i
                                class="cursor-pointer text-red-500 fas fa-trash"
                                @click="deleteUserRole(props.row._id)"
                            />
                        </div>
                    </td>
                </template>
            </datatable>
        </Card>
    </div>
</template>

<script>
import "material-design-icons-iconfont"
import Card from "../../components/UI/Card.vue"
import Button from "../../components/UI/Button.vue"
import { http } from "../../utils/http"
import "vue-good-table/dist/vue-good-table.css"
// import { VueGoodTable } from 'vue-good-table';
import DataTable from "vue-materialize-datatable"

export default {
    components: { Card, Button, datatable: DataTable },
    data() {
        return {
            roles: [],

            name: "",
            showForm: false,
            initialized: false,
            creatingUserRole: false,
            columns: [
                {
                    label: "Id",
                    field: "_id",
                    type: "number",
                },
                {
                    label: "Name",
                    field: "name",
                },
                // {
                //   label: 'Created On',
                //   field: 'createdAt',
                //   type: 'date',
                //   dateInputFormat: 'yyyy-MM-dd',
                //   dateOutputFormat: 'MMM do yy',
                // },
            ],
        }
    },
    mounted() {
        this.loadUserRoles()
    },
    methods: {
        async loadUserRoles() {
            try {
                const res = await http.get("/user-roles")
                this.roles = res.data.result
            } catch (err) {
                console.log("error in loading rams", err)
            }
            this.initialized = true
        },

        async createUserRole() {
            this.creatingUserRole = true
            try {
                const createdRes = await http.post("/user-roles", {
                    name: this.name,
                })
                const createdGrade = createdRes.data.result
                this.roles.push(createdGrade)
                this.name = ""

                this.$toast.success("User Role Created Successfully")
            } catch (err) {
                console.log("error in creating User Role", err)
                this.$toast.error("Some error occured")
            }
            this.creatingUserRole = false
        },
        async deleteUserRole(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete("/user-roles/" + id)
                    this.$toast.success("User Role Deleted Successfully")
                    this.roles = this.roles.filter((item) => item.id !== id)
                    window.location.href = "/user-roles"
                }
            } catch (err) {
                console.log("error in deleting user role", err)
                this.$toast.error("Some error occured")
            }
        },
    },
}
</script>

<style></style>
