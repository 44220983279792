<template>
    <div>
        <!-- Form for editing/creating -->
        <h3 class="my-3">
            <strong>{{ editMode ? "Edit" : "Create" }} Certificate</strong>
        </h3>
        <div v-if="!fetchedCertificate">Please wait...</div>
        <form @submit.prevent="handleSubmit" v-else>
            <div class="row gy-3">
                <div class="col-md-8">
                    <label for="title">Title:</label>
                    <input class="form-control" type="text" id="title" v-model="title" required />
                </div>
                <div class="col-md-4">
                    <label for="courseCollectionId">Course Collection:</label>
                    <select class="form-select" id="courseCollectionId" v-model="courseCollectionId">
                        <option value="">Select Course Collection</option>
                        <option v-for="courseCollection of courseCollections" :key="courseCollection._id" :value="courseCollection._id">
                            {{ courseCollection.title }}
                        </option>
                        <!-- Options for course collections -->
                    </select>
                </div>
                <!-- <div class="col-md-12">
                    <div>
                        <label for="state">States:</label>
                    </div>
                    <SelectDropdown
                        :id="`form-role`"
                        v-if="states.length && showStates"
                        :multiple="true"
                        :options="
                            states.map((item) => ({
                                ...item,
                                title: item.name,
                            }))
                        "
                        :value="stateIds"
                        @change="stateIds = $event"
                    />
                    <div class="my-3">
                        <button type="button" class="btn btn-primary btn-sm" @click="clearAllStates">
                            <i class="fas fa-times"></i> Clear All States
                        </button>
                        <button type="button" class="btn btn-primary btn-sm mx-2" @click="selectAllStates">
                            <i class="fas fa-check"></i> Select All States
                        </button>
                    </div>
                </div> -->

                <div class="col-md-4">
                    <label for="fontSize">General Font Size:</label>
                    <select class="form-select" id="fontSize" v-model="generalFontSizePixels" required>
                        <option value="">Select Font Size</option>
                        <option v-for="fontSize of fontSizes" :key="fontSize" :value="fontSize">{{ fontSize }} px</option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="fontSizeForDate">Font Size for Date:</label>
                    <select class="form-select" id="fontSize" v-model="dateFontSizePixels">
                        <option value="">Select Font Size</option>
                        <option v-for="fontSize of fontSizes" :key="fontSize" :value="fontSize">{{ fontSize }} px</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="textColor">Text Color:</label>
                    <input class="form-control" type="color" id="textColor" v-model="textColor" />
                </div>

                <div class="col-md-4">
                    <label for="font">Select Font:</label>
                    <select id="font" class="form-select" v-model="fontName">
                        <option value="Arial">Arial</option>
                        <option value="Helvetica">Helvetica</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="Trebuchet MS">Trebuchet MS</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Open Sans">Open Sans</option>
                    </select>
                </div>
            </div>

            <div class="my-3">
                <label for="backgroundImage">Default Background Image:</label>
                <input class="form-control" type="file" id="backgroundImage" @change="handleImageUpload" :required="!editMode" />
            </div>
            <div v-if="existingBackgroundImage">
                <div class="my-2">Current Background</div>
                <img style="max-width: 270px" :src="serverImgUrl + existingBackgroundImage" alt="" />
            </div>

            <CustomTable>
                <template #head>
                    <tr>
                        <th>State</th>
                        <th>Background Image</th>
                    </tr>
                </template>
                <template #body>
                    <tr v-for="(state, i) of statesForEdit" :key="state.id">
                        <td>{{ i + 1 }}. {{ state.name }}</td>
                        <td>
                            <div v-if="state.backgroundImageSrc">
                                <img class="mb-2" :src="serverImgUrl + state.backgroundImageSrc" style="width: 100px; height: 100px" />
                            </div>
                            <input type="file" @change="handleBackgroundImageUpdate($event, state)" />
                        </td>
                    </tr>
                </template>
            </CustomTable>

            <h3 class="my-2"><strong>Variables:</strong></h3>
            <div class="row my-3">
                <div class="col-md-4">
                    <CertificateCoordinateSelection
                        id="showUserName"
                        title="User Name"
                        :currentShowCoordinates="showUserName"
                        :currentCoordX="userNamePositionX"
                        :currentCoordY="userNamePositionY"
                        @check="showUserName = $event"
                        @coordX="userNamePositionX = $event"
                        @coordY="userNamePositionY = $event"
                    />
                </div>
                <div class="col-md-4">
                    <CertificateCoordinateSelection
                        id="showDate"
                        title="Date"
                        :currentShowCoordinates="showDate"
                        :currentCoordX="datePositionX"
                        :currentCoordY="datePositionY"
                        @check="showDate = $event"
                        @coordX="datePositionX = $event"
                        @coordY="datePositionY = $event"
                    />
                </div>
                <div class="col-md-4">
                    <CertificateCoordinateSelection
                        id="showTestName"
                        title="Test Name"
                        :currentShowCoordinates="showTestName"
                        :currentCoordX="testNamePositionX"
                        :currentCoordY="testNamePositionY"
                        @check="showTestName = $event"
                        @coordX="testNamePositionX = $event"
                        @coordY="testNamePositionY = $event"
                    />
                </div>
                <div class="col-md-4">
                    <CertificateCoordinateSelection
                        id="showTestScore"
                        title="Test Score"
                        :currentShowCoordinates="showTestScore"
                        :currentCoordX="testScorePositionX"
                        :currentCoordY="testScorePositionY"
                        @check="showTestScore = $event"
                        @coordX="testScorePositionX = $event"
                        @coordY="testScorePositionY = $event"
                    />
                </div>
                <!-- Repeat similar checkbox groups for other labels -->
            </div>
            <router-link to="/certificates"><Button type="button" class="btn btn-primary">Cancel & Back</Button></router-link>
            <Button class="ms-2 btn btn-primary">
                <span v-if="processingForm">Please wait...</span>
                <span v-else>{{ editMode ? "Update" : "Create" }} Certificate</span>
            </Button>
            <Button class="ms-2 btn btn-primary" v-if="editMode" type="button">
                <span v-if="previewing">Please wait...</span>
                <span @click="previewCertificate" v-else>Preview Certificate</span>
            </Button>
        </form>
    </div>
</template>

<script>
import { mapState } from "vuex"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import Button from "../../components/UI/Button.vue"
import { errMsg } from "../../utils/common"
import { http, serverImgUrl } from "../../utils/http"
import CertificateCoordinateSelection from "./CertificateCoordinateSelection.vue"
import CustomTable from "../../components/UI/CustomTable.vue"

export default {
    data() {
        return {
            showStates: true,
            statesForEdit: [],
            serverImgUrl,
            fetchedCertificate: false,
            editMode: false,
            courseCollections: [],
            fontSizes: [10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 38, 42],
            courseCollectionId: "",
            stateIds: [],
            existingBackgroundImage: "",
            backgroundImage: "",
            title: "",
            showUserName: false,
            userNamePositionX: "",
            userNamePositionY: "",
            showDate: false,
            datePositionX: "",
            datePositionY: "",
            showTestName: false,
            testNamePositionX: "",
            testNamePositionY: "",
            showTestScore: false,
            testScorePositionX: "",
            testScorePositionY: "",
            generalFontSizePixels: "",
            dateFontSizePixels: "",
            textColor: "",
            fontName: "",
            processingForm: false,
            previewing: false,
            // Add similar variables for other labels
        }
    },

    components: { SelectDropdown, Button, CertificateCoordinateSelection, CustomTable },
    computed: {
        ...mapState("misc", ["states"]),
    },
    async mounted() {
        // Fetch course collections and other necessary data
        try {
            await this.fetchCourseCollections()
            await this.fetchCertificate()
            this.fetchedCertificate = true
        } catch (err) {}
    },
    methods: {
        handleBackgroundImageUpdate(event, state) {
            if (event.target.files.length) {
                state.backgroundImage = event.target.files[0]
            }
        },
        clearAllStates() {
            this.showStates = false
            this.stateIds = []
            setTimeout(() => {
                this.showStates = true
            }, 0)
        },
        selectAllStates() {
            this.showStates = false
            this.stateIds = this.states.map((item) => item._id)
            setTimeout(() => {
                this.showStates = true
            }, 0)
        },
        async previewCertificate() {
            if (this.previewing) return
            try {
                if (!this.existingBackgroundImage) {
                    return this.$toast.error("Default Background image is not present!")
                }
                this.previewing = true
                const res = await http.post(`/certificate-masters/${this.$route.params.id}/preview`)
                const url = res.data.result
                if (url) {
                    window.open(serverImgUrl + url, "_blank")
                } else {
                    this.$toast.error("Failed Previewing")
                }
            } catch (err) {
                console.log("error in previewing", err)
                this.$toast.error("Failed Previewing")
            }
            this.previewing = false
        },
        async handleSubmit() {
            if (this.processingForm) return
            try {
                this.processingForm = true
                const submitData = {
                    courseCollectionId: this.courseCollectionId || null,
                    stateIds: this.stateIds.filter((item) => !!item) || null,
                    backgroundImage: this.backgroundImage,
                    title: this.title,
                    showUserName: this.showUserName,
                    userNamePositionX: this.userNamePositionX,
                    userNamePositionY: this.userNamePositionY,
                    showDate: this.showDate,
                    datePositionX: this.datePositionX,
                    datePositionY: this.datePositionY,
                    showTestName: this.showTestName,
                    testNamePositionX: this.testNamePositionX,
                    testNamePositionY: this.testNamePositionY,
                    showTestScore: this.showTestScore,
                    testScorePositionX: this.testScorePositionX,
                    testScorePositionY: this.testScorePositionY,
                    generalFontSizePixels: this.generalFontSizePixels,
                    dateFontSizePixels: this.dateFontSizePixels,
                    textColor: this.textColor,
                    fontName: this.fontName,
                }
                const formData = new FormData()
                for (let key of Object.keys(submitData)) {
                    formData.append(key, submitData[key] || "")
                }

                for (let stateForEdit of this.statesForEdit) {
                    if (stateForEdit.backgroundImage) {
                        formData.append(`state_${stateForEdit._id}`, stateForEdit.backgroundImage)
                    }
                }

                if (this.editMode) {
                    await http.patch(`/certificate-masters/${this.$route.params.id}`, formData)
                    window.location.reload()
                } else {
                    await http.post(`/certificate-masters`, formData)
                    window.location.href = "/certificates"
                }
                this.$toast.success(`Certificate ${this.editMode ? "updated" : "created"} successfully`)
            } catch (err) {
                console.log("error in submitting form", err)
                this.$toast.error(errMsg(err, "Error in submitting"))
            }
            this.processingForm = false
        },
        handleImageUpload(event) {
            // Handle image upload logic
            this.backgroundImage = event.target.files[0]
        },
        async fetchCertificate() {
            const id = this.$route.params.id
            if (id) {
                try {
                    this.editMode = true
                    const res = await http.get(`/certificate-masters/${id}`)
                    const result = res.data.result
                    this.courseCollectionId = result.courseCollectionId
                    this.stateIds = result.stateIds || []
                    this.existingBackgroundImage = result.backgroundImage
                    this.backgroundImage = result.backgroundImage
                    this.title = result.title
                    this.showUserName = result.showUserName
                    this.userNamePositionX = result.userNamePositionX
                    this.userNamePositionY = result.userNamePositionY
                    this.showDate = result.showDate
                    this.datePositionX = result.datePositionX
                    this.datePositionY = result.datePositionY
                    this.showTestName = result.showTestName
                    this.testNamePositionX = result.testNamePositionX
                    this.testNamePositionY = result.testNamePositionY
                    this.showTestScore = result.showTestScore
                    this.testScorePositionX = result.testScorePositionX
                    this.testScorePositionY = result.testScorePositionY
                    this.generalFontSizePixels = result.generalFontSizePixels
                    this.dateFontSizePixels = result.dateFontSizePixels
                    this.textColor = result.textColor
                    this.fontName = result.fontName
                    this.statesForEdit = res.data.statesForEdit
                } catch (err) {
                    console.log("error in getting certificate", err)
                }
            } else {
                this.statesForEdit = this.states
            }
        },
        async fetchCourseCollections() {
            try {
                const res = await http.get(`/course-collections`)
                this.courseCollections = res.data.result
            } catch (err) {
                console.log("error in getting certificate", err)
            }
        },
        // Add other methods as needed
    },
}
</script>

<style scoped>
/* Add scoped styles as needed */
</style>
