<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="User"
            :viewColumns="columns"
            :fetchUrl="`/users`"
            updateRootUrl="/users"
            editRouteName="user-edit"
            deleteRootUrl="/users"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"

export default {
    data() {
        return {
            loading: true,
            videos: [],
            createFields: [],
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("First Name", "firstName", "string"),
                createColumn("Last Name", "lastName", "string"),
                createColumn("Email", "email", "string"),
                // createColumn("Status", "status", "string"),
            ],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: { CrudIndex },
    async mounted() {
        await this.fetchVideos()
        if (!this.videoId) {
            this.createFields = [
                createField(
                    "Video*",
                    "videoId",
                    "select",
                    true,
                    "",
                    this.videos.map((item) => ({
                        _id: item._id,
                        title: item.title,
                    }))
                ),
            ]
        }

        this.createFields = this.createFields.concat([
            createField("Title*", "title", "text", true),
            createField(
                `Video Time In Seconds To Trigger`,
                "videoTimeInSecondsToTrigger",
                "timebar|0|1000",
                true
            ),
            createField("Description", "description", "textarea", false),
        ])

        if (this.videoId) {
            this.createFields.push(createField("videoId", "videoId", "hidden", true, this.videoId))
        }
        this.loading = false
    },
    methods: {
        async fetchVideos() {
            console.log("fetch videos")
            const res = await http.get("/videos")
            this.videos = res.data.result
        },
    },
}
</script>

<style></style>
