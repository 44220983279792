<template>
  <div>
    <div class="text-black font-bold">
      Create Video Progress
      <!-- <span
        @click="showForm = !showForm"
        class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
      >
        <i class="fas fa-minus" v-if="showForm" />
        <i class="fas fa-add" v-else />
      </span> -->
    </div>
    <Card v-if="showForm">
      <form class="my-3" @submit.prevent="createVideoProgress">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">User Id</div>

            <div>
              <select
                required
                class="form-select"
                v-model="userId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select User</option>
                <option v-for="user of users" :value="user._id" :key="user._id">
                  {{ user.firstName }} {{ user.lastName }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="mb-2">Video</div>

            <div>
              <select
                required
                class="form-select"
                v-model="videoId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select Quiz</option>
                <option
                  v-for="video of videos"
                  :value="video._id"
                  :key="video._id"
                >
                  {{ video.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">lastWatchTimeInSeconds</div>
            <div>
              <input
                required
                v-model="lastWatchTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="my-2">Max Watch Time In Seconds</div>
            <div>
              <input
                required
                v-model="maxWatchTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="my-2">Completed Quiz Ids </div>
            <div>
              <input
                required
                v-model="completedQuizIds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-2">Is Completed</div>
            <div>
              <select class="form-select" v-model="isCompleted">
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
          </div>
         
          
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ creatingVideoProgress ? "Please wait..." : "Create" }}
        </Button>
      </form>
    </Card>
    <Card>
      <h1 class="font-semibold mb-3">Quiz History</h1>
      <div class="grid grid-cols-3 bg-green-200 p-2">
        <div>Id</div>
        <div>Video Progress Video Id</div>
        <div>Action</div>
      </div>
      <div
        class="p-4 text-center text-sm"
        v-if="progressVideos.length <= 0 && initialized"
      >
        No Progress-Videos Found
      </div>
      <div
        class="grid grid-cols-3 p-2"
        v-for="progressVideo of progressVideos"
        :key="progressVideo._id"
      >
        <div>{{ progressVideo._id }}</div>
        <div>{{ progressVideo.videoId }}</div>

        <div>
          <!-- <router-link
              :to="{
                name: 'quiz-history-edit',
                params: { id: quizHistory._id },
              }"
            >
              <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
            </router-link> -->
          <i
            class="cursor-pointer text-red-500 fas fa-trash"
            @click="deleteQuizHistory(progressVideo._id)"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from "../../components/UI/Card.vue";
  import Button from "../../components/UI/Button.vue";
  import { http } from "../../utils/http";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        userId: "",
        videoId: "",
        lastWatchTimeInSeconds: "",
        maxWatchTimeInSeconds: "",
        completedQuizIds: "",
        totalQuestions: "",
        completionTimeInSeconds: "",
        questionAnswerHistory: "",isCompleted:false,
        videos: [],
        users: [],
        progressVideos: [],
        showForm: false,
        initialized: false,
        creatingVideoProgress: false,
      };
    },
    mounted() {
      this.loadQuizHistory();
      this.loadUsers();
    },
    methods: {
      updateImage(e) {
        this.imageUrl = e.target.files[0];
      },

      async loadUsers() {
        try {
          const res = await http.get("/users");
          const res2 = await http.get("/videos");
          this.users = res.data.result;
          this.videos = res2.data.result;
        } catch (err) {
          console.log("error in loading users or videos", err);
        }
        this.initialized = true;
      },
      async loadQuizHistory() {
        try {
          const res = await http.get("/video-progress");
          this.progressVideos = res.data.result;
        } catch (err) {
          console.log("error in loading Quiz Questions", err);
        }
        this.initialized = true;
      },

      async createVideoProgress() {
        this.creatingVideoProgress = true;
        try {
          await http.post("/video-progress", {
            userId: this.userId,
            videoId: this.videoId,
            lastWatchTimeInSeconds: this.lastWatchTimeInSeconds,
            maxWatchTimeInSeconds: this.maxWatchTimeInSeconds,
            completedQuizIds: this.completedQuizIds,
            totalQuestions: this.totalQuestions,
            completionTimeInSeconds: this.completionTimeInSeconds,
            questionAnswerHistory: this.questionAnswerHistory,
            isCompleted: this.isCompleted,
          });

          this.userId = "";
          this.videoId = "";
          this.lastWatchTimeInSeconds = "";
          this.maxWatchTimeInSeconds = "";
          this.completedQuizIds = "";
          this.isCompleted = "";
         
          this.$toast.success("Video Progress Added Successfully");
          window.location.href = "/video-progress";
        } catch (err) {
          console.log("error in Adding Quiz History", err);
          this.$toast.error("Some error occured");
        }

        this.creatingVideoProgress = false;
      },
      async deleteQuizHistory(id) {
        try {
          if (confirm("Do you really want to delete?")) {
            await http.delete("/video-progress/" + id);
            this.$toast.success("Video-progress option Deleted Successfully");
            this.progressVideos = this.progressVideos.filter(
              (item) => item.id !== id
            );
            window.location.href = "/video-progress";
          }
        } catch (err) {
          console.log("error in creating History", err);
          this.$toast.error("Some error occured");
        }
      },
    },
  };
</script>

<style></style>
