<template>
    <div>
        <div class="video-tabs">
            <router-link
                class="video-edit-link"
                exact-active-class="video-edit-link-active"
                :to="{ name: 'video-edit.general', params: { id: $route.params.id } }"
                >General</router-link
            >
            <router-link
                class="video-edit-link"
                exact-active-class="video-edit-link-active"
                :to="{ name: 'video-edit.quizzes', params: { id: $route.params.id } }"
                >Quizzes</router-link
            >

            <router-link
                class="video-edit-link"
                exact-active-class="video-edit-link-active"
                :to="{ name: 'video-edit.chapters', params: { id: $route.params.id } }"
                >Chapters</router-link
            >
            <router-link
                class="video-edit-link"
                :to="{ name: 'video-edit.subtitles', params: { id: $route.params.id } }"
                exact-active-class="video-edit-link-active"
                >Subtitles
            </router-link>
            <router-link
                class="video-edit-link"
                :to="{ name: 'video-edit.reviews', params: { id: $route.params.id } }"
                exact-active-class="video-edit-link-active"
                >Reviews
            </router-link>
            <router-link
                class="video-edit-link"
                exact-active-class="video-edit-link-active"
                :to="{ name: 'video-edit.discussion', params: { id: $route.params.id } }"
            >
                Discussion
            </router-link>
        </div>
        <div class="text-black font-bold">
            <Card>
                <router-view></router-view>
            </Card>
        </div>
    </div>
</template>

<script>
import Card from "../../components/UI/Card.vue"

export default {
    components: { Card },
}
</script>

<style lang="scss" scoped>
.video-edit-link {
    display: inline-block;
    padding: 15px;
    border-bottom: 2px solid transparent;
}
.video-edit-link-active {
    border-bottom: 2px solid blue;
}
</style>
