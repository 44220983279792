<template>
    <form class="mb-3" @submit.prevent="updateVideo" v-if="video">
        <Button @click="$router.push('/videos')"
            ><i class="fas fa-chevron-left"></i> Back to Videos</Button
        >
        <div class="row">
            <div class="col-6 mb-3">
                <div class="mb-2">Subject</div>
                <select
                    class="form-select"
                    v-model="video.subjectId"
                    aria-label=".form-select-sm example"
                >
                    <option :selected="!video.subjectId">Select Option</option>
                    <option :value="subject._id" v-for="subject of subjects" :key="subject._id">
                        {{ subject.title }}
                    </option>
                </select>
            </div>

            <div class="col-6 mb-3">
                <div class="mb-2">Course Collection</div>
                <select
                    class="form-select"
                    v-model="video.courseCollectionId"
                    aria-label=".form-select-sm example"
                >
                    <option :selected="!video.courseCollectionId">Select Option</option>
                    <option
                        :value="courseCollection._id"
                        v-for="courseCollection of courseCollections"
                        :key="courseCollection._id"
                    >
                        {{ courseCollection.title }}
                    </option>
                </select>
            </div>

            <div class="col-6">
                <label class="mb-3">Name</label>
                <div>
                    <input v-model="video.title" type="text" class="form-control mb-3" />
                </div>
            </div>

            <div class="col-6">
                <div class="mt -2">Description</div>
                <div>
                    <HtmlEditor @change="video.description = $event" :value="video.description" />
                </div>
            </div>
            <div class="col-md-6 mt-2">
                <div class="my-3">Update Thumbnail</div>
                <img v-if="video.thumbnail" :src="serverURL + video.thumbnail" />
                <input
                    type="file"
                    class="form-control"
                    name="thumbnail"
                    @change="updateImage"
                    accept="image/*"
                />
            </div>

            <div class="col-6">
                <label class="mb-3">Video</label>
                <!-- <div>
              <input
                v-model="video.videoUrl"
                type="text"
                class="form-control mb-3"
              />
            </div> -->
                <video width="320" height="240" controls v-if="video.videoUrl">
                    <source v-if="video.videoUrl" :src="serverURL + video.videoUrl" />
                </video>
                <input type="file" class="form-control" name="videoUrl" @change="updateVideoFile" />
            </div>
            <hr class="my-3" />
            <div class="col-6">
                <div class="my-2">Status</div>
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        :id="`video-status`"
                        v-model="video.status"
                    />
                    <label class="form-check-label" :for="`video-status`"> Active </label>
                </div>
            </div>
            <div class="col-6">
                <div class="my-2">For Evaluation Test</div>
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        :id="`video-evaluation-test`"
                        v-model="video.forEvaluationTest"
                    />
                    <label class="form-check-label" :for="`video-evaluation-test`"> Yes </label>
                </div>
            </div>
            <!-- <div class="col-6">
              <div class="mb-2">Status</div>
              <div>
                <select class="form-select" v-model="video.video_status">
                  <option :value="true">Active</option>
                  <option :value="false">Inactive</option>
                </select>
              </div>
            </div> -->
        </div>

        <Button
            class="btn btn-success bg-green-500 my-3 mt-4 border-none"
            style="min-width: 200px"
            type="submit"
        >
            {{ updatingVideo ? "Please wait..." : "Update" }}
        </Button>
    </form>
</template>

<script>
import { http, serverURL } from "../../../utils/http"
import Card from "../../../components/UI/Card.vue"
import HtmlEditor from "../../../components/other/HtmlEditor.vue"

import Button from "../../../components/UI/Button.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            video: null,
            subjects: [],
            courseCollections: [],
            image: "",
            serverURL,
            updatingVideo: false,
            deleteImage: false,
            deleteFeaturedImage: false,
        }
    },

    async mounted() {
        try {
            const res = await http.get("/videos/" + this.$route.params.id)

            this.video = res.data.result
            console.log(" this.video", this.video)
        } catch (err) {
            console.log("error in loading video", err)
        }
        await this.loadSubjects()
        await this.loadCourseCollections()
    },

    methods: {
        updateImage(e) {
            this.video.thumbnail = e.target.files[0]
        },
        updateVideoFile(e) {
            this.video.videoUrl = e.target.files[0]
        },

        async loadCourseCollections() {
            try {
                const res = await http.get("/course-collections")
                this.courseCollections = res.data.result
            } catch (err) {
                console.log("error in loading subjects", err)
            }
        },
        async loadSubjects() {
            try {
                const res = await http.get("/subjects")
                this.subjects = res.data.result
            } catch (err) {
                console.log("error in loading subjects", err)
            }
        },

        async updateVideo() {
            this.updatingVideo = true
            if (!this.video.title) {
                this.$toast.error("File is required")
            } else if (!this.video.subjectId) {
                this.$toast.error("Subject is required")
            } else {
                try {
                    const formData = new FormData()
                    // console.log("update video course collection id", this.video.courseCollectionId)
                    formData.append("subjectId", this.video.subjectId || "")
                    formData.append("courseCollectionId", this.video.courseCollectionId || "")
                    formData.append("title", this.video.title || "")
                    formData.append("description", this.video.description || "")
                    formData.append("thumbnail", this.video.thumbnail || "")

                    formData.append("videoUrl", this.video.videoUrl || "")
                    formData.append("status", this.video.status || false)
                    formData.append("forEvaluationTest", this.video.forEvaluationTest || false)
                    formData.append(
                        "videoDurationInSeconds",
                        this.video.videoDurationInSeconds || ""
                    )

                    await http.patch("/videos/" + this.$route.params.id, formData)

                    this.$toast.success("Video Updated Successfully")
                    // window.location.href = "/videos"
                } catch (err) {
                    console.log("error in Updating video", err)
                    this.$toast.error("Some error occured")
                }
            }
            this.updatingVideo = false
        },
    },
}
</script>

<style></style>
