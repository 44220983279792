<template>
  <div class="text-black font-bold">
    Edit Discussion

    <Card>
      <form class="my-3" @submit.prevent="updateDiscussion">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">User</div>
            <select
              class="form-select"
              v-model="discussion.userId"
              aria-label=".form-select-sm example"
            >
              <option :selected="!discussion.userId">Select Option</option>
              <option :value="user._id" v-for="user of users" :key="user._id">
                {{ user.firstName }} {{ user.lastName }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <div class="mb-2">Videos</div>
            <select
              class="form-select"
              v-model="discussion.videoId"
              aria-label=".form-select-sm example"
            >
              <option :selected="!discussion.videoId">Select Option</option>
              <option
                :value="video._id"
                v-for="video of videos"
                :key="video._id"
              >
                {{ video.title }}
              </option>
            </select>
          </div>

          <div class="col-6">
            <label class="mb-3">Title</label>
            <div>
              <input
                v-model="discussion.title"
                type="text"
                class="form-control mb-3"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="mt -2">Description</div>
            <div>
              <HtmlEditor
                @change="discussion.description = $event"
                :value="discussion.description"
              />
            </div>
          </div>
          <div class="col-md-6 mt-2">
            <div class="my-3">Update Thumbnail</div>
            <img v-if="discussion.imageUrl" :src="serverURL + discussion.imageUrl" />
            <input
              type="file"
              class="form-control"
              name="imageUrl"
              @change="updateimageUrl"
              accept="image/*"
            />
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ updatingDiscussion ? "Please wait..." : "Update" }}
        </Button>
      </form>
    </Card>
  </div>
</template>

<script>
  import { http, serverURL } from "../../utils/http";
  import Card from "../../components/UI/Card.vue";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";
  import Button from "../../components/UI/Button.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        discussion: {},
        videos: [],
        users: [],
        imageUrl: "",
        serverURL,

        updatingDiscussion: false,
      };
    },

    async mounted() {
      try {
        const res = await http.get("/discussions/" + this.$route.params.id);

        this.discussion = res.data.result;
      } catch (err) {
        console.log("error in loading discussion", err);
      }
      this.loadVideos();
    },

    methods: {
      updateimageUrl(e) {
        this.discussion.imageUrl = e.target.files[0];
      },
     

      async loadVideos() {
        try {
          const res = await http.get("/users");
          this.users = res.data.result;
          const res2 = await http.get("/videos");
          this.videos = res2.data.result;
        } catch (err) {
          console.log("error in loading videos/users", err);
        }
        this.initialized = true;
      },

      async updateDiscussion() {
        this.updatingDiscussion = true;
        if (!this.discussion.title) {
          this.$toast.error("Title is required");
        } else {
          try {
            const formData = new FormData();
            formData.append("userId", this.discussion.userId || "");
            formData.append("videoId", this.discussion.videoId || "");
            formData.append("title", this.discussion.title || "");
            formData.append("description", this.discussion.description || "");
            formData.append("imageUrl", this.discussion.imageUrl || "");
           
            await http.patch("/discussions/" + this.$route.params.id, formData);

            this.$toast.success("Discussion Updated Successfully");
            window.location.href = "/discussions";
          } catch (err) {
            console.log("error in Updating Discussion", err);
            this.$toast.error("Some error occured");
          }
        }
        this.updatingDiscussion = false;
      },
    },
  };
</script>

<style></style>
