<template>
    <div class="text-black font-bold">
        Edit Quiz Answer Option

        <Card>
            <form class="my-3" @submit.prevent="updateQuizAnswerOption">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-2">Quiz Questions</div>
                        <select
                            class="form-select"
                            v-model="quizAnswerOption.quizQuestionMasterId"
                            aria-label=".form-select-sm example"
                        >
                            <option :selected="!quizAnswerOption.quizQuestionMasterId">
                                Select Option
                            </option>
                            <option
                                :value="quizQuestion._id"
                                v-for="quizQuestion of quizQuestions"
                                :key="quizQuestion._id"
                            >
                                {{ quizQuestion.title }}
                            </option>
                        </select>
                    </div>

                    <div class="col-6">
                        <label class="mb-3">Title</label>
                        <div>
                            <input
                                v-model="quizAnswerOption.title"
                                type="text"
                                class="form-control mb-3"
                            />
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="mt -2">Description</div>
                        <div>
                            <HtmlEditor
                                @change="quizAnswerOption.description = $event"
                                :value="quizAnswerOption.description"
                            />
                        </div>
                    </div>
                    <div class="col-md-6 mt-2">
                        <div class="my-3">Update Thumbnail</div>
                        <img
                            v-if="quizAnswerOption.imageUrl"
                            :src="serverURL + quizAnswerOption.imageUrl"
                        />
                        <input
                            type="file"
                            class="form-control"
                            name="imageUrl"
                            @change="updateImage"
                            accept="image/*"
                        />
                    </div>
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ updatingquizAnswerOption ? "Please wait..." : "Update" }}
                </Button>
            </form>
        </Card>
    </div>
</template>

<script>
import { http, serverURL } from "../../utils/http"
import Card from "../../components/UI/Card.vue"
import HtmlEditor from "../../components/other/HtmlEditor.vue"
import Button from "../../components/UI/Button.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            quizAnswerOption: [],
            quizQuestions: [],
            image: "",
            serverURL,

            updatingquizAnswerOption: false,
            deleteImage: false,
            deleteFeaturedImage: false,
        }
    },

    async mounted() {
        try {
            const res = await http.get("/quiz-answer-options/" + this.$route.params.id)

            this.quizAnswerOption = res.data.result
        } catch (err) {
            console.log("error in loading quizAnswerOption", err)
        }
        this.loadQuiz()
    },

    methods: {
        updateImage(e) {
            this.quizAnswerOption.imageUrl = e.target.files[0]
        },

        async loadQuiz() {
            try {
                const res = await http.get("/quiz-questions")
                this.quizQuestions = res.data.result
            } catch (err) {
                console.log("error in loading quizItems", err)
            }
            this.initialized = true
        },

        async updateQuizAnswerOption() {
            this.updatingquizAnswerOption = true
            if (!this.quizAnswerOption.title) {
                this.$toast.error("Title is required")
            } else if (!this.quizAnswerOption.quizQuestionMasterId) {
                this.$toast.error("Quiz is required")
            } else {
                try {
                    const formData = new FormData()
                    formData.append(
                        "quizQuestionMasterId",
                        this.quizAnswerOption.quizQuestionMasterId || ""
                    )
                    formData.append("title", this.quizAnswerOption.title || "")
                    formData.append("description", this.quizAnswerOption.description || "")
                    formData.append("imageUrl", this.quizAnswerOption.imageUrl || "")

                    await http.patch("/quiz-answer-options/" + this.$route.params.id, formData)

                    this.$toast.success("Quiz Answer Option Updated Successfully")
                    window.location.href = "/quiz-answer-option"
                } catch (err) {
                    console.log("error in Updating quiz-answer-options", err)
                    this.$toast.error("Some error occured")
                }
            }
            this.updatingquizAnswerOption = false
        },
    },
}
</script>

<style></style>
