<template>
    <div>
        <div class="text-black font-bold mb-3" v-if="createUrl && !onlyEdit">
            Create {{ title }}
            <span
                @click="enableCreateForm"
                class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
            >
                <i class="fas fa-minus" v-if="showForm && !editMode" />
                <i class="fas fa-add" v-else />
            </span>
        </div>
        <div v-if="showForm">
            <Form
                :fields="createFields"
                :title="title"
                :submitUrl="createUrl"
                :updateRootUrl="updateRootUrl"
                @success="handleCreateSuccess"
                @cancel="disableEditing(true)"
                :editMode="editMode"
                :editData="editData"
            />
        </div>
        <div v-if="loading" class="d-flex justify-content-center my-5">
            <div class="spinner-border text-primary"></div>
        </div>
        <div style="font-weight: normal" v-if="!onlyEdit && !loading && !dataItems.length">
            No Data Found
        </div>
        <datatable
            :title="`${title} data`"
            :columns="columns"
            :rows="dataItemFilter(dataItems)"
            v-if="!onlyEdit && dataItems.length"
        >
            <th slot="thead-tr">Actions</th>
            <template slot="tbody-tr" slot-scope="props">
                <td>
                    <div>
                        <div class="d-flex" style="align-items: center; gap: 5px">
                            <slot :itemId="props.row._id" />
                            <a
                                href="javascript:void(0)"
                                v-if="editRouteName"
                                @click="
                                    navigatePage({
                                        name: editRouteName,
                                        params: { id: props.row._id },
                                    })
                                "
                            >
                                <i
                                    style="font-size: 15px"
                                    class="cursor-pointer text-green-500 px-3 fas fa-edit"
                                />
                            </a>
                            <a
                                v-else
                                @click="
                                    enableEditing(
                                        dataItems.find((item) => item._id == props.row._id)
                                    )
                                "
                            >
                                <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
                            </a>
                            <i
                                class="cursor-pointer text-red-500 fas fa-trash"
                                @click="deleteDataItem(props.row._id)"
                            />
                        </div>
                    </div>
                </td>
            </template>
        </datatable>
    </div>
</template>

<script>
import "material-design-icons-iconfont"
import Card from "../../components/UI/Card.vue"
import Button from "../../components/UI/Button.vue"
import Form from "../../components/crud/form.vue"
import { http } from "../../utils/http"
import "vue-good-table/dist/vue-good-table.css"
// import { VueGoodTable } from 'vue-good-table';
import DataTable from "vue-materialize-datatable"

export default {
    components: { Form, Card, Button, datatable: DataTable },
    data() {
        return {
            dataItems: [],
            name: "",
            showForm: false,
            initialized: false,
            processing: false,
            columns: [],
            loading: false,
            editMode: false,
            editData: null,
        }
    },
    props: {
        reloadOnSuccess: {
            type: Boolean,
            default: false,
        },
        backRoute: {
            type: String,
            default: "",
        },
        editingFormatter: {
            type: Function,
            default: (data) => {
                return data
            },
        },
        dataItemFilter: {
            type: Function,
            default: (data) => {
                return data
            },
        },
        allowGoBack: {
            type: Boolean,
            default: false,
        },
        onlyEdit: {
            type: Boolean,
            default: false,
        },
        onlyEditData: {
            type: Object,
            default: () => null,
        },
        createRouteName: { type: String, default: "" },
        editRouteName: { type: String, default: "" },
        createFields: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "",
        },
        fetchUrl: {
            type: String,
            default: "",
        },
        createUrl: {
            type: String,
            defualt: "",
        },
        updateRootUrl: {
            type: String,
            defualt: "",
        },
        deleteRootUrl: {
            type: String,
            default: "",
        },
        viewColumns: {
            type: Array,
            default: () => [
                // {
                //     label: "Id",
                //     field: "_id",
                //     type: "number",
                // },
                // {
                //     label: "Name",
                //     field: "name",
                // },
            ],
        },
    },
    mounted() {
        console.log("teh cssurd index is mounted", this.onlyEdit, this.viewColumns, this.fetchUrl)
        this.columns = this.viewColumns
        if (this.onlyEdit) {
            this.enableEditing(this.onlyEditData)
        } else {
            this.fetchData()
        }
    },
    methods: {
        navigatePage(routeObj) {
            window.location.href = this.$router.resolve(routeObj).href
        },
        async enableCreateForm() {
            if (this.createRouteName) {
                this.$router.push({
                    name: this.createRouteName,
                })
                return
            }
            this.editData = null

            // for new state
            const showFormOriginal = this.showForm
            this.showForm = false

            setTimeout(() => {
                this.showForm = showFormOriginal
                if (this.showForm && !this.editMode) {
                    this.showForm = false
                } else {
                    this.showForm = true
                }
                this.editMode = false
            }, 0)
        },
        async enableEditing(editItem) {
            this.disableEditing()

            setTimeout(() => {
                this.editMode = true
                this.editData = this.editingFormatter(editItem)
                this.showForm = true
            }, 0)
            window.scroll({ top: 0, behavior: "smooth" })
        },
        async disableEditing(cancelMode) {
            window.scroll({ top: 0, behavior: "smooth" })
            if (this.onlyEdit) {
                if (this.allowGoBack && cancelMode) {
                    this.$router.go(-1)
                }
            } else {
                this.editMode = false
                this.editData = null
                this.showForm = false
            }
        },
        async handleCreateSuccess(result) {
            if (this.onlyEdit) {
                if (this.allowGoBack) {
                    if (this.backRoute) {
                        return this.$router.push(this.backRoute)
                    }
                    this.$router.go(-1)
                }
                return
            }
            if (this.editMode) {
                this.dataItems = this.dataItems.map((item) => {
                    if (item._id == result._id) {
                        return result
                    }
                    return item
                })
            } else {
                this.dataItems.unshift(result)
            }
            if (this.reloadOnSuccess) {
                window.location.reload()
            }
            this.disableEditing()
        },
        async fetchData() {
            this.loading = true
            try {
                const res = await http.get(this.fetchUrl)
                this.dataItems = res.data.result
            } catch (err) {
                console.log("error in loading data", err)
            }
            this.initialized = true
            this.loading = false
        },
        async deleteDataItem(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete(`${this.deleteRootUrl}/${id}`)
                    this.$toast.success(this.title + " Deleted Successfully!")
                    this.dataItems = this.dataItems.filter((item) => item._id !== id)
                    this.disableEditing()
                }
            } catch (err) {
                console.log("error in deleting " + this.title + " item", err)
                this.$toast.error("Some error occured")
            }
        },
    },
}
</script>

<style></style>
