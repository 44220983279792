<template>
    <div>
        <h4 class="group-heading" @click="showMenu = !showMenu">
            {{ sidebarLinkGroup.title }}
            <i :class="[{ 'fas fa-chevron-down': !showMenu }, { 'fas fa-chevron-up': showMenu }]" />
        </h4>
        <div v-if="showMenu">
            <template v-for="sidebarLink of sidebarLinkGroup.data">
                <a
                    v-if="sidebarLink.forceReload"
                    :class="[
                        'block outline-none sidebar-item',
                        { active: $route.path === sidebarLink.link },
                    ]"
                    style="color: white"
                    :key="sidebarLink.link"
                    :href="sidebarLink.link"
                    >{{ sidebarLink.title }}
                </a>
                <router-link
                    v-else
                    :class="[
                        'block outline-none sidebar-item',
                        { active: $route.path === sidebarLink.link },
                    ]"
                    @click.native="scrollTopFirst"
                    :key="sidebarLink.link"
                    :to="sidebarLink.link"
                    >{{ sidebarLink.title }}</router-link
                >
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sidebarLinkGroup: { type: Object, default: () => ({}) },
    },
    data() {
        return { showMenu: false }
    },
    mounted() {
        for (let sidebarLink of this.sidebarLinkGroup.data) {
            if (this.$route.path === sidebarLink.link) {
                this.showMenu = true
            }
        }
    },
    methods: {
        scrollTopFirst() {
            window.scrollTo({ top: 0, behavior: "smooth" })
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.group-heading {
    padding-top: 25px;
    padding: 20px;
    padding-left: 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #333;
}
.sidebar-item {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-bottom: 5px;
    padding: 15px;

    &.active {
        color: $primaryColor;
        font-weight: bold;
        border-right: 6px solid $primaryColor;
        background-color: $secondaryColor;
    }
    &:hover:not(.active) {
        background-color: lighten($secondaryColor, 4%);
    }
}
.group-heading:hover {
    color: #333;
}
</style>
