<template>
    <select :id="id" :multiple="multiple" class="form-select form-select-sm" :disabled="disabled">
        <!-- select option expects id and title for creating its option fields -->
        <option value="">Select An Option</option>
        <option v-for="option of options" :key="option._id" :value="option._id">
            {{ option.title }}
        </option>
    </select>
</template>

<script>
export default {
    emits: ["change"],
    props: {
        id: {
            type: String,
            default: [],
        },
        options: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        field: {
            type: String,
            default: "",
        },
        value: {
            type: [String, Object, Array],
            default: () => null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        setTimeout(() => {
            window.$(`#${this.id}`).select2()

            if (this.value) {
                $(`#${this.id}`).val(this.value)
                $(`#${this.id}`).trigger("change")
            }

            // set up event listeners for select 2
            const handleSelectUnselect = (e, remove = false) => {
                var data = e.params.data
                if (this.multiple) {
                    let prevData = this.value || []
                    console.log("handle select unselect 1")
                    if (remove) {
                        this.$emit(
                            "change",
                            prevData.filter((item) => item != data.id)
                        )
                    } else {
                        this.$emit("change", [...new Set([...prevData, data.id])])
                    }
                } else {
                    console.log("handle select unselect 2")
                    this.$emit("change", data.id)
                }
            }

            $(`#${this.id}`).on("select2:select", (e) => {
                handleSelectUnselect(e, false)
            })
            $(`#${this.id}`).on("select2:unselect", (e) => {
                handleSelectUnselect(e, true)
            })
        }, 100)
    },
}
</script>

<style lang="scss">
.select2-selection {
    background-color: #efefef !important;
    padding: 5px !important;
    min-height: 38px !important;
    border: 0px solid transparent !important;
}
.select2-container {
    border: 3px solid transparent !important;
}
.select2-container--open {
    border: 3px solid #3f85ff !important;
}
.select2-dropdown {
    border: none !important;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4) !important;
}
.select2-results__options {
    font-size: 0.8em !important;
}
:not(.select2-selection--multiple) {
    .select2-search__field {
        border: none !important;
        font-size: 0.8em !important;
        border-bottom: 3px solid #999 !important;
        outline: none !important;
    }
}
.select2-selection__choice {
    border: none !important;
    background-color: #3f85ff !important;
    color: white !important;
}
.select2-selection__choice__remove {
    color: white !important;
    &:hover {
        color: #333 !important;
    }
}
</style>
