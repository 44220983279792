<template>
    <div class="sidebar-container shadow overflow-hidden" style="min-height: 100vh; height: 100%">
        <div class="d-flex justify-content-center">
            <img src="@/assets/logo.png" style="width: 120px" alt="" />
        </div>
        <div v-for="(sidebarLinkGroup, i) of sidebarLinks" :key="i" class="sidebar-link-group ml-3">
            <SidebarLinkGroup :sidebarLinkGroup="sidebarLinkGroup" :key="i" />
        </div>
    </div>
</template>

<script>
import SidebarLinkGroup from "./SidebarLinkGroup.vue"
import { mapState, mapActions } from "vuex"
export default {
    computed: {
        ...mapState("auth", ["authenticated"]),
    },
    components: { SidebarLinkGroup },
    data() {
        return {
            sidebarLinks: [
                {
                    title: "Masters",
                    data: [
                        {
                            link: "/dashboard",
                            title: "Dashboard",
                        },
                        // {
                        //     link: "/courses",
                        //     title: "Courses",
                        // },
                        {
                            link: "/collections",
                            title: "Course Collections",
                        },
                        {
                            link: "/custom-dictionary",
                            title: "Dictionary",
                        },
                        {
                            link: "/certificates",
                            title: "Certificates",
                        },
                        {
                            link: "/subject-master",
                            title: "Subject Master",
                        },
                        // {
                        //     link: "/state-master",
                        //     title: "State Master",
                        // },

                        {
                            link: "/videos",
                            title: "Videos",
                        },

                        // {
                        //     link: "/quiz",
                        //     title: "Quiz",
                        // },
                        // {
                        //     link: "/quiz-question-selected",
                        //     title: "Quiz Questions",
                        // },
                        {
                            link: "/quiz-question",
                            title: "Question Master",
                        },
                        // {
                        //     link: "/quiz-answer-option",
                        //     title: "Answer Options Master",
                        // },
                        // {
                        //     link: "/quiz-answer",
                        //     title: "Answer Master",
                        // },
                        // {
                        //     link: "/quiz-history",
                        //     title: "Quiz History",
                        // },
                        // {
                        //     link: "/video-progress",
                        //     title: "Video Progress",
                        // },
                        // {
                        //     link: "/subject-progress",
                        //     title: "Subject Progress",
                        // },
                        // {
                        //     link: "/video-subtitle",
                        //     title: "Video Subtitle",
                        // },
                        // {
                        //     link: "/video-chapter",
                        //     title: "Video Chapter",
                        // },
                        // {
                        //     link: "/users",
                        //     title: "Users",
                        // },
                    ],
                },
                {
                    title: "Other",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        // {
                        //     link: "/reviews",
                        //     title: "Reviews",
                        // },
                        {
                            link: "/events",
                            title: "Events",
                        },
                        // {
                        //     link: "/discussions",
                        //     title: "Discussion",
                        // },
                        // {
                        //     link: "/certificates",
                        //     title: "Certificates",
                        // },
                        {
                            link: "/user-roles",
                            title: "User Role",
                        },
                        {
                            link: "/users",
                            title: "Users",
                        },

                        // {
                        //     link: "/customer",
                        //     title: "Customer",
                        //     forceReload: true,
                        // },
                        // {
                        //     link: "/product",
                        //     title: "Product",
                        //     forceReload: true,
                        // },
                        // {
                        //     link: "/orders",
                        //     title: "Orders",
                        //     forceReload: true,
                        // },
                    ],
                },
                {
                    title: "Reports",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        // {
                        //     link: "/reports",
                        //     title: "Analytic Reports",
                        // },
                        {
                            link: "/reports/quiz",
                            title: "Quiz Reports",
                        },
                        {
                            link: "/reports/videos",
                            title: "Video Reports",
                        },
                        {
                            link: "/reports/geographical-stats",
                            title: "Geographical Stats",
                        },
                        {
                            link: "/reports/subject-stats",
                            title: "Subject Stats",
                        },
                        {
                            link: "/reports/video-stats",
                            title: "Video Stats",
                        },
                        {
                            link: "/reports/user-stats",
                            title: "User Stats (coming soon)",
                        },
                    ],
                },
                {
                    title: "Tickets & Queries",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        // {
                        //     link: "/reports",
                        //     title: "Analytic Reports",
                        // },
                        {
                            link: "/tickets",
                            title: "Ticket List",
                        },
                        {
                            link: "/ticket-statuses",
                            title: "Ticket Statuses",
                        },
                        {
                            link: "/ticket-priorities",
                            title: "Ticket Priorities",
                        },
                        {
                            link: "/ticket-categories",
                            title: "Ticket Categories",
                        },
                    ],
                },
            ],
        }
    },
    methods: {},
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.sidebar-container {
    padding-left: 25px;
    position: relative;
    z-index: 100;
    font-size: 0.8em;
    background-color: white;
}
</style>
