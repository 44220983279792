<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Courses"
            :viewColumns="columns"
            fetchUrl="/courses"
            createUrl="/courses"
            updateRootUrl="/courses"
            deleteRootUrl="/courses"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"

export default {
    data() {
        return {
            subjects: [],
            videoOptions: [],
            searchField: "",
            selectedSubject: "",
            loading: true,
            createFields: [],
            noResults: false,
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Course Title", "title", "string"),
                createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectDropdown },
    async mounted() {
        // this.subjects = await this.fetchSubjects()
        this.videoOptions = await this.fetchVideoOptions()
        this.createFields = [
            createField("Title*", "title", "text", true),
            createField(
                "Videos*",
                "videoIds",
                "multiselect",
                true,
                "",
                this.videoOptions.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField("Description", "description", "textarea", false),
            createField("Thumbnail Image", "thumbnailUrl", "image", false),
            createField("Status", "status", "switch", false, true),
        ]
        this.loading = false
    },
    methods: {
        async fetchSubjects() {
            const res = await http.get("/subjects")
            return res.data.result
        },
        async fetchVideoOptions() {
            const res = await http.get("/videos/options")
            return res.data.result
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
    },
}
</script>

<style></style>
