<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Course Collections"
            :viewColumns="columns"
            fetchUrl="/course-collections"
            createUrl="/course-collections"
            updateRootUrl="/course-collections"
            deleteRootUrl="/course-collections"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"

export default {
    data() {
        return {
            subjects: [],
            videoOptions: [],
            searchField: "",
            selectedSubject: "",
            loading: true,
            createFields: [],
            noResults: false,
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Order Number", "orderNumber", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectDropdown },
    async mounted() {
        this.videoOptions = await this.fetchVideoOptions()
        this.createFields = [
            createField("Title*", "title", "text", true),
            createField("Order Number", "orderNumber", "number", true),
            createField("Description", "description", "textarea", false),
            createField("Thumbnail Image", "thumbnailUrl", "image", false),
            createField("Status", "status", "switch", false, true),
            createField("Show Subtitles", "showSubTitles", "switch", false, true),
            createField("Show Dictionary", "showDictionary", "switch", false, true),
            createField("Show About Lesson", "showAboutLesson", "switch", false, true),
            createField("Show Video Chapters", "showChapters", "switch", false, true),
            createField(
                "Videos*",
                "videoIds",
                "multiselect",
                true,
                "",
                this.videoOptions.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField(
                "Videos For Test*",
                "videoIdsForTest",
                "multiselect",
                true,
                "",
                this.videoOptions.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
        ]
        this.loading = false
    },
    methods: {
        async fetchVideoOptions() {
            const res = await http.get("/videos/options")
            return res.data.result
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
    },
}
</script>

<style></style>
