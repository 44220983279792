<template>
    <div v-if="!authInitialized" class="d-flex justify-content-center align-items-center py-5">
        <img src="@/assets/Triangles-1s-200px.gif" alt="" />
    </div>
    <div v-else>
        <div class="main-bg w-full" style="background-color: #ebedef; min-height: 100vh">
            <div class="row">
                <div class="col-md-12 p-4" v-if="!authenticated">
                    <LoginForm />
                </div>
                <div class="col-3" style="padding: 0px" v-if="authenticated">
                    <Sidebar />
                </div>
                <div
                    :class="[{ 'col-9': authenticated }, { 'col-md-12': !authenticated }]"
                    style="padding: 0px; display: flex; flex-direction: column"
                >
                    <Header v-if="authenticated" />
                    <div class="p-2" style="flex: 1" v-if="authenticated">
                        <div class="bg-white shadow p-2">
                            <router-view v-if="authenticated" style="padding: 8px" />
                        </div>
                    </div>
                    <div class="border-top text-muted small" v-if="authenticated">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 p-4">
                                    &copy; Copyright Video App, All rights reserved.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import Sidebar from "./components/layout/Sidebar.vue"
import Header from "./components/layout/Header.vue"
import LoginForm from "./components/layout/LoginForm.vue"
export default {
    name: "App",
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
        }),
        ...mapState("auth", ["authInitialized"]),
        ...mapState("auth", ["user"]),
    },
    components: { Sidebar, LoginForm, Header },
    data() {
        return { loggingOut: false }
    },
    methods: {
        ...mapActions("auth", ["initAuth"]),
        ...mapActions("auth", ["logout"]),
        ...mapActions("misc", ["loadTopUsers", "fetchMasters"]),
        async logout() {
            this.loggingOut = true
            await this.logout()
            window.location.href = "/login"
        },
    },
    async mounted() {
        await this.initAuth()
        if (this.authenticated) {
            this.loadTopUsers()
            this.fetchMasters()
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
body {
    font-size: 0.8em;
}
.main-bg {
    background: #e9fcfb;
}
</style>
