<template>
    <SelectedQuestionIndex />
</template>

<script>
import SelectedQuestionIndex from "@/components/SelectedQuestionIndex"
export default {
    components: { SelectedQuestionIndex },
}
</script>

<style></style>
