<template>
    <div :class="['answer-option', { 'active-option': answerOption.isCorrect }]">
        <div class="row my-2">
            <div class="col-1 option-column">
                {{ id }}
            </div>
            <div class="col-5 option-column">
                <input type="text" class="form-control form-control-sm" v-model="title" />
            </div>
            <div class="col-3 option-column pt-1">
                <div class="form-check ml-5" style="border: none">
                    <input
                        type="radio"
                        :id="`answer-option-${id}`"
                        class="form-check-input"
                        @change="handleCheck"
                        :checked="answerOption.isCorrect"
                    />
                    <label
                        class="form-check-label"
                        style="cursor: pointer"
                        :for="`answer-option-${id}`"
                        >Select</label
                    >
                </div>
            </div>
            <div class="col-3 option-column">
                <button class="delete-btn" @click="removeOption">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "",
        }
    },
    props: {
        id: { type: [String, Number], default: 0 },
        answerOption: {
            type: Object,
            default: () => null,
        },
    },
    mounted() {
        if (this.answerOption) {
            this.title = this.answerOption.title
        }
    },
    methods: {
        emitUpdate(isCorrect, isCorrectSpecified) {
            if (this.answerOption) {
                const result = {
                    optionId: this.answerOption.optionId,
                    title: this.title,
                    isCorrect: this.answerOption.isCorrect,
                }
                if (isCorrectSpecified) {
                    result.isCorrect = isCorrect
                }
                this.$emit("update", { answerOption: result, isCorrectSpecified })
            }
        },
        removeOption() {
            this.$emit("remove", this.answerOption)
        },
        handleCheck(e) {
            this.emitUpdate(e.target.value, true)
        },
    },
    watch: {
        title(e) {
            this.emitUpdate()
        },
    },
}
</script>

<style lang="scss" scoped>
.answer-option {
    padding: 2px 5px;
}
.active-option {
    background-color: #ddd;
}
.delete-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgb(246, 30, 30);
    color: white;
    cursor: pointer;
    display: grid;
    place-items: center;
    font-size: 14px;
    &:hover {
        background-color: rgb(255, 73, 73);
    }
}
.answer-option-item {
    padding: 10px;
}
.option-column {
}
</style>
