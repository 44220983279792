<template>
    <div>
        <div class="mb-3">
            <h4 class="h6"><strong>Filter</strong></h4>
            <div class="bg-white p-3">
                <div class="row">
                    <div class="col-md-4">
                        <div class="my-2">Search Question</div>
                        <div>
                            <input
                                type="text"
                                v-model="searchField"
                                class="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="my-2">Select Subject</div>
                        <div>
                            <SelectDropdown
                                :id="`form-subject`"
                                :options="subjects"
                                :value="selectedSubject"
                                @change="selectedSubject = $event"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="noResults" class="my-3">No Results</div>
            </div>
        </div>
        <CrudIndex
            v-if="!loading"
            title="Quiz Question"
            :viewColumns="columns"
            fetchUrl="/quiz-questions"
            createUrl="/quiz-questions"
            updateRootUrl="/quiz-questions"
            deleteRootUrl="/quiz-questions"
            :createFields="createFields"
            editRouteName="quiz-question-edit"
            :editingFormatter="editingFormatter"
            :dataItemFilter="dataItemFilter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import SelectDropdown from "./crud/SelectDropdown.vue"

export default {
    data() {
        return {
            subjects: [],
            searchField: "",
            selectedSubject: "",
            loading: true,
            createFields: [],
            noResults: false,
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Question", "title", "string"),
                createColumn("Subject", "subjectId.title", "string"),
                createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex, SelectDropdown },
    async mounted() {
        this.subjects = await this.fetchSubjects()
        this.createFields = [
            createField("Title*", "title", "text", true),
            createField(
                "Subject*",
                "subjectId",
                "select",
                true,
                "",
                this.subjects.map((item) => ({
                    _id: item._id,
                    title: item.title,
                }))
            ),
            createField("Description", "description", "textarea", false),
            createField("Related Image", "imageUrl", "image", false),
            createField("Status", "status", "switch", false, true),
        ]
        this.loading = false
    },
    methods: {
        async fetchSubjects() {
            const res = await http.get("/subjects")
            return res.data.result
        },
        editingFormatter(item) {
            return {
                ...item,
                subjectId: item.subjectId ? item.subjectId._id : "",
            }
        },
        dataItemFilter(items) {
            console.log("old items", items)
            if (this.searchField) {
                items = items.filter((item) => item.title.includes(this.searchField))
            }
            if (this.selectedSubject) {
                items = items.filter((item) => item.subjectId._id == this.selectedSubject)
            }
            console.log("new items", items)
            if (!items.length) {
                this.noResults = true
            } else {
                this.noResults = false
            }
            return items
        },
    },
}
</script>

<style></style>
