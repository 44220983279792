<template>
    <div>
        <CrudIndex
            title="Answer Option"
            :viewColumns="columns"
            :fetchUrl="`/quiz-answer-options?populate=true&question_id=${questionId}`"
            createUrl="/quiz-answer-options"
            updateRootUrl="/quiz-answer-options"
            deleteRootUrl="/quiz-answer-options"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
        >
            <template v-slot="slotProps">
                <button
                    style="font-size: 0.85em"
                    :class="[
                        'btn btn-secondary mr-2 btn-sm',
                        { 'btn-success': quizAnswerOptionMasterId == slotProps.itemId },
                    ]"
                    :disabled="markingAnswer"
                    @click="markAsAnswer(slotProps.itemId)"
                >
                    {{
                        quizAnswerOptionMasterId == slotProps.itemId
                            ? "Marked Answer"
                            : "Mark As Answer"
                    }}
                </button>
            </template>
        </CrudIndex>
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            createFields: [],
            columns: [
                createColumn("Id", "_id", "number"),
                createColumn("Title", "title", "string"),
            ],
            questions: [],
            quizzes: [],
            quizAnswerOptionMasterId: "",
            markingAnswer: false,
        }
    },
    props: { questionId: { type: String, default: "" } },
    components: { CrudIndex },
    async mounted() {
        await this.fetchQuestions()
        await this.fetchMarkedAnswer()

        if (!this.questionId) {
            this.createFields = this.createFields.concat([
                createField(
                    "Question*",
                    "quizQuestionMasterId",
                    "select",
                    true,
                    "",
                    this.questions.map((item) => ({
                        _id: item._id,
                        title: item.title,
                    }))
                ),
            ])
        }

        this.createFields = this.createFields.concat([
            createField("Title*", "title", "text", true),
            createField("Description", "description", "textarea", false),
            createField("Add Image", "imageUrl", "image", false),
        ])

        if (this.questionId) {
            this.createFields = this.createFields.concat([
                createField(
                    "quizQuestionMasterId",
                    "quizQuestionMasterId",
                    "hidden",
                    true,
                    this.questionId
                ),
            ])
        }

        this.loading = false
    },
    methods: {
        async markAsAnswer(itemId) {
            if (itemId === this.quizAnswerOptionMasterId) {
                return this.$toast.error("Already marked as answer!")
            }
            this.markingAnswer = true
            try {
                await http.patch(`/quiz-answers/${itemId}/mark-answer`)
                this.quizAnswerOptionMasterId = itemId
                this.$toast.success("Marked as answer!")
            } catch (err) {
                console.log("error in marking answer...")
            }
            this.markingAnswer = false
        },
        async fetchQuestions() {
            const res = await http.get("/quiz-questions")
            this.questions = res.data.result
        },
        async fetchMarkedAnswer() {
            const res = await http.get(`/quiz-answers?question_id=${this.questionId}`)
            if (res.data.result.length) {
                this.quizAnswerOptionMasterId = res.data.result[0].quizAnswerOptionMasterId
            }
        },
        editingFormatter(item) {
            return item
            // return {
            //     ...item,
            //     questionId: item.questionId._id,
            //     quizQuestionMasterId: item.quizQuestionMasterId._id,
            // }
        },
    },
}
</script>

<style></style>
