<template>
  <div class="text-black font-bold">
    Edit Reviews

    <Card>
      <form class="my-3" @submit.prevent="updateReview">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">User</div>
            <select
              class="form-select"
              v-model="review.userId"
              aria-label=".form-select-sm example"
            >
              <option :selected="!review.userId">Select Option</option>
              <option :value="user._id" v-for="user of users" :key="user._id">
                {{ user.firstName }} {{ user.lastName }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <div class="mb-2">Videos</div>
            <select
              class="form-select"
              v-model="review.videoId"
              aria-label=".form-select-sm example"
            >
              <option :selected="!review.videoId">Select Option</option>
              <option
                :value="video._id"
                v-for="video of videos"
                :key="video._id"
              >
                {{ video.title }}
              </option>
            </select>
          </div>

          <div class="col-6">
            <label class="mb-3">Title</label>
            <div>
              <input
                v-model="review.title"
                type="text"
                class="form-control mb-3"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="mt -2">Description</div>
            <div>
              <HtmlEditor
                @change="review.description = $event"
                :value="review.description"
              />
            </div>
          </div>
          <div class="col-md-6 mt-2">
            <div class="my-3">Update Thumbnail</div>
            <img v-if="review.image1" :src="serverURL + review.image1" />
            <input
              type="file"
              class="form-control"
              name="image1"
              @change="updateImage1"
              accept="image/*"
            />
          </div>

          <div class="col-md-6 mt-2">
            <div class="my-3">Update Image2</div>
            <img v-if="review.image2" :src="serverURL + review.image2" />
            <input
              type="file"
              class="form-control"
              name="image2"
              @change="updateImage2"
              accept="image/*"
            />
          </div>

          <div class="col-md-6 mt-2">
            <div class="my-3">Update Image 3</div>
            <img v-if="review.image3" :src="serverURL + review.image3" />
            <input
              type="file"
              class="form-control"
              name="image3"
              @change="updateImage3"
              accept="image/*"
            />
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ updatingReview ? "Please wait..." : "Update" }}
        </Button>
      </form>
    </Card>
  </div>
</template>

<script>
  import { http, serverURL } from "../../utils/http";
  import Card from "../../components/UI/Card.vue";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";
  import Button from "../../components/UI/Button.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        review: {},
        videos: [],
        users: [],
        image: "",
        serverURL,

        updatingReview: false,
       
        
      };
    },

    async mounted() {
      try {
        const res = await http.get("/reviews/" + this.$route.params.id);

        this.review = res.data.result;
      } catch (err) {
        console.log("error in loading review", err);
      }
      this.loadVideos();
    },

    methods: {
      updateImage1(e) {
        this.review.image1 = e.target.files[0];
      },
      updateImage2(e) {
        this.review.image2 = e.target.files[0];
      },
      updateImage3(e) {
        this.review.image3 = e.target.files[0];
      },

      async loadVideos() {
        try {
          const res = await http.get("/users");
          this.users = res.data.result;
          const res2 = await http.get("/videos");
          this.videos = res2.data.result;
        } catch (err) {
          console.log("error in loading videos/users", err);
        }
        this.initialized = true;
      },

      async updateReview() {
        this.updatingReview = true;
        if (!this.review.title) {
          this.$toast.error("Title is required");
        } else {
          try {
            const formData = new FormData();
            formData.append("userId", this.review.userId || "");
            formData.append("videoId", this.review.videoId || "");
            formData.append("title", this.review.title || "");
            formData.append("description", this.review.description || "");
            formData.append("image1", this.review.image1 || "");
            formData.append("image2", this.review.image2 || "");
            formData.append("image3", this.review.image3 || "");

            await http.patch(
              "/reviews/" + this.$route.params.id,
              formData
            );

            this.$toast.success("Review Updated Successfully");
            window.location.href = "/reviews";
          } catch (err) {
            console.log("error in Updating Reviews", err);
            this.$toast.error("Some error occured");
          }
        }
        this.updatingReview = false;
      },
    },
  };
</script>

<style></style>
