<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Review"
            :viewColumns="columns"
            :fetchUrl="`/reviews?video_id=${$route.params.id}`"
            createUrl="/reviews"
            updateRootUrl="/reviews"
            deleteRootUrl="/reviews"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../../components/crud/index.vue"
import { createField, createColumn } from "../../../utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            loading: false,
            users: [],
            createFields: [],
            columns: [
                createColumn("User", "userId", "number"),
                createColumn("Title", "title", "string"),
                createColumn("Rating", "rating", "number"),
            ],
        }
    },
    async mounted() {
        this.loading = true
        try {
            const res = await http.get("/users")
            this.users = res.data.result
            this.createFields = [
                createField("User*", "userId", "select", true, null, [
                    ...this.users.map((item) => ({
                        _id: item._id,
                        title: `${item.firstName} ${item.lastName} (${item._id.slice(-5)})`,
                    })),
                ]),
                createField("Title*", "title", "text", true),
                createField("Rating*", "rating", "select", true, null, [
                    {
                        id: "",
                        title: "Select Option",
                    },
                    {
                        id: 1,
                        title: "1 star",
                    },
                    {
                        id: 2,
                        title: "2 stars",
                    },
                    {
                        id: 3,
                        title: "3 stars",
                    },
                    {
                        id: 4,
                        title: "4 stars",
                    },
                    {
                        id: 5,
                        title: "5 stars",
                    },
                ]),
                createField("Description", "description", "textarea", false),
                createField("Image 1", "image1", "image", false),
                createField("Image 2", "image2", "image", false),
                createField("Image 3", "image3", "image", false),
                createField("Video Id", "videoId", "hidden", true, this.$route.params.id),
            ]
        } catch (error) {
            console.log("error in loading users", error)
        }
        this.loading = false
    },
    components: { CrudIndex },
}
</script>

<style></style>
