<template>
    <div class="text-black font-bold">
        Edit Video Chapter

        <Card>
            <form class="my-3" @submit.prevent="updateVideoChapter">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-2">Video</div>
                        <select
                            class="form-select"
                            v-model="videoChapter.videoId"
                            aria-label=".form-select-sm example"
                        >
                            <option :selected="!videoChapter.videoId">Select Option</option>
                            <option :value="video._id" v-for="video of videos" :key="video._id">
                                {{ video.title }}
                            </option>
                        </select>
                    </div>
                    <div class="col-6">
                        <div class="my-2">StartTimeInSeconds</div>
                        <div>
                            <input
                                v-model="videoChapter.startTimeInSeconds"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="my-2">EndTimeInSeconds</div>
                        <div>
                            <input
                                required
                                v-model="videoChapter.endTimeInSeconds"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="my-2">Title</div>
                        <div>
                            <input v-model="videoChapter.title" type="text" class="form-control" />
                        </div>
                    </div>
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ updatingChapter ? "Please wait..." : "Update" }}
                </Button>
            </form>
        </Card>
    </div>
</template>
k
<script>
import { http, serverURL } from "../../utils/http"
import Card from "../../components/UI/Card.vue"
import HtmlEditor from "../../components/other/HtmlEditor.vue"
import Button from "../../components/UI/Button.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            videos: [],
            answerOptions: [],

            serverURL,
            videoChapter: {},

            updatingChapter: false,
            deleteImage: false,
            deleteFeaturedImage: false,
        }
    },

    async mounted() {
        try {
            const res = await http.get("/video-chapters/" + this.$route.params.id)

            this.videoChapter = res.data.result
        } catch (err) {
            console.log("error in loading videoChapter", err)
        }
        this.loadVideo()
    },

    methods: {
        async loadVideo() {
            try {
                const res = await http.get("/videos")

                this.videos = res.data.result
            } catch (err) {
                console.log("error in loading Video", err)
            }
            this.initialized = true
        },

        async updateVideoChapter() {
            this.updatingChapter = true

            try {
                await http.patch("/video-chapters/" + this.$route.params.id, {
                    videoId: this.videoChapter.videoId,
                    startTimeInSeconds: this.videoChapter.startTimeInSeconds,
                    endTimeInSeconds: this.videoChapter.endTimeInSeconds,
                    title: this.videoChapter.title,
                })

                // this.cat.push(updatedDataItem)
                this.$toast.success("Video chapters Updated Successfully")
                window.location.href = "/video-chapter"
            } catch (err) {
                console.log("error in Updating data", err)
                this.$toast.error("Some error occured")
            }

            this.updatingChapter = false
        },
    },
}
</script>

<style></style>
