import { http } from "@/utils/http"
import { formatIsoDate, daysToMs } from "@/utils/common"

const state = {
    pageTitle: "",
    loadingTopUsers: false,
    topUsers: [],
    states: [],
}

const getters = {}

const mutations = {
    SET_DATA: (state, data) => {
        for (let key in data) {
            state[key] = data[key]
        }
    },
}

const actions = {
    setData(vuexContext, data) {
        vuexContext.commit("SET_DATA", data)
    },
    async loadTopUsers({ state }, dateData = null) {
        let startDate = formatIsoDate(new Date(new Date().getTime() - daysToMs(90)))
        let endDate = formatIsoDate(new Date())
        let statesCsv = ""
        if (dateData) {
            startDate = dateData.startDate
            endDate = dateData.endDate
            statesCsv = dateData.states
        }
        state.loadingTopUsers = true
        try {
            const tableReports = await http.get(
                `/reports/dashboard?fetchUsers=true&start_date=${startDate}&end_date=${endDate}&states=${statesCsv}`
            )
            state.topUsers = tableReports.data.result && tableReports.data.result.topUsers
        } catch (err) {
            console.log("error in loadingTopUsers customers", err)
        }
        state.loadingTopUsers = false
    },
    async fetchMasters({ state }, dateData = null) {
        state.loadingTopUsers = true
        try {
            const res = await http.get(`/states`)
            state.states = res.data.result
        } catch (err) {
            console.log("error in states", err)
        }
        state.loadingTopUsers = false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
