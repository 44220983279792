<template>
  <div>
    <div class="text-black font-bold">
      Create Quiz History
      <!-- <span
        @click="showForm = !showForm"
        class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
      >
        <i class="fas fa-minus" v-if="showForm" />
        <i class="fas fa-add" v-else />
      </span> -->
    </div>
    <Card v-if="showForm">
      <form class="my-3" @submit.prevent="createQuizHistory">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">User Questions</div>

            <div>
              <select
                required
                class="form-select"
                v-model="userId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select User</option>
                <option v-for="user of users" :value="user._id" :key="user._id">
                  {{ user.firstName }} {{ user.lastName }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="mb-2">Quiz</div>

            <div>
              <select
                required
                class="form-select"
                v-model="quizId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select Quiz</option>
                <option
                  v-for="quizItem of quizItems"
                  :value="quizItem._id"
                  :key="quizItem._id"
                >
                  {{ quizItem.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">Score</div>
            <div>
              <input
                required
                v-model="score"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="my-2">Correct Answers</div>
            <div>
              <input
                required
                v-model="correctAnswers"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="my-2">InCorrect Answers</div>
            <div>
              <input
                required
                v-model="incorrectAnswers"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">Total Questions</div>
            <div>
              <input
                required
                v-model="totalQuestions"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">Completion Time In Seconds</div>
            <div>
              <input
                v-model="completionTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">Question Answer History</div>
            <div>
              <input
                v-model="questionAnswerHistory"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ creatingQuizHistory ? "Please wait..." : "Create" }}
        </Button>
      </form>
    </Card>
    <Card>
      <h1 class="font-semibold mb-3">Quiz History</h1>
      <div class="grid grid-cols-3 bg-green-200 p-2">
        <div>Id</div>
        <div>History Score</div>
        <div>Action</div>
      </div>
      <div
        class="p-4 text-center text-sm"
        v-if="quizHistoryies.length <= 0 && initialized"
      >
        No History Found
      </div>
      <div
        class="grid grid-cols-3 p-2"
        v-for="quizHistory of quizHistoryies"
        :key="quizHistory._id"
      >
        <div>{{ quizHistory._id }}</div>
        <div>{{ quizHistory.score }}</div>

        <div>
          <!-- <router-link
            :to="{
              name: 'quiz-history-edit',
              params: { id: quizHistory._id },
            }"
          >
            <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
          </router-link> -->
          <i
            class="cursor-pointer text-red-500 fas fa-trash"
            @click="deleteQuizHistory(quizHistory._id)"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from "../../components/UI/Card.vue";
  import Button from "../../components/UI/Button.vue";
  import { http } from "../../utils/http";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        userId: "",
        quizId: "",
        score: "",
        correctAnswers: "",
        incorrectAnswers: "",
        totalQuestions: "",
        completionTimeInSeconds: "",
        questionAnswerHistory: "",
        quizItems: [],
        users: [],
        quizHistoryies: [],
        showForm: false,
        initialized: false,
        creatingQuizHistory: false,
      };
    },
    mounted() {
      this.loadQuizHistory();
      this.loadUsers();
    },
    methods: {
      updateImage(e) {
        this.imageUrl = e.target.files[0];
      },

      async loadUsers() {
        try {
          const res = await http.get("/users");
          const res2 = await http.get("/quiz");
          this.users = res.data.result;
          this.quizItems = res2.data.result;
        } catch (err) {
          console.log("error in loading users or videos", err);
        }
        this.initialized = true;
      },
      async loadQuizHistory() {
        try {
          const res = await http.get("/quiz-history");
          this.quizHistoryies = res.data.result;
        } catch (err) {
          console.log("error in loading Quiz Questions", err);
        }
        this.initialized = true;
      },

      async createQuizHistory() {
        this.creatingQuizHistory = true;
        try {
          await http.post("/quiz-history", {
            userId: this.userId,
            quizId: this.quizId,
            score: this.score,
            correctAnswers: this.correctAnswers,
            incorrectAnswers: this.incorrectAnswers,
            totalQuestions: this.totalQuestions,
            completionTimeInSeconds: this.completionTimeInSeconds,
            questionAnswerHistory: this.questionAnswerHistory,
          });

          this.userId = "";
          this.quizId = "";
          this.score = "";
          this.correctAnswers = "";
          this.incorrectAnswers = "";
          this.totalQuestions = "";
          this.completionTimeInSeconds = "";
          this.questionAnswerHistory = "";

          this.$toast.success("Quiz History Added Successfully");
          window.location.href = "/quiz-history";
        } catch (err) {
          console.log("error in Adding Quiz History", err);
          this.$toast.error("Some error occured");
        }

        this.creatingQuizHistory = false;
      },
      async deleteQuizHistory(id) {
        try {
          if (confirm("Do you really want to delete?")) {
            await http.delete("/quiz-history/" + id);
            this.$toast.success("Quiz Answer option Deleted Successfully");
            this.quizHistoryies = this.quizHistoryies.filter(
              (item) => item.id !== id
            );
            window.location.href = "/quiz-history";
          }
        } catch (err) {
          console.log("error in creating History", err);
          this.$toast.error("Some error occured");
        }
      },
    },
  };
</script>

<style></style>
