<template>
    <QuestionIndex />
</template>

<script>
import QuestionIndex from "@/components/QuestionIndex.vue"

export default {
    data() {
        return {}
    },
    components: { QuestionIndex },
}
</script>

<style></style>
