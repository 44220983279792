<template>
    <div>
        <div class="my-2">Quiz Reports</div>
        <div>
            <!-- filters -->
            <div class="card card-body my-3">
                <div class="row">
                    <div class="col-md-12">
                        <DateRangeFilter @change="handleDateChange" />
                    </div>
                    <div class="col-md-4">
                        <div class="my-2">Subject</div>
                        <SelectDropdown
                            v-if="subjects && subjects.length"
                            :id="`form-subject`"
                            :options="
                                subjects.map((item) => ({ _id: item._id, title: item.title }))
                            "
                            :value="selectedSubjectId"
                            @change="selectedSubjectId = $event"
                        />
                    </div>
                    <div class="col-md-4" v-if="selectedSubjectId">
                        <div class="my-2">Video</div>
                        <SelectDropdown
                            v-if="videos && videos.length"
                            :id="`form-video-subject`"
                            :options="videos.map((item) => ({ _id: item._id, title: item.title }))"
                            :value="selectedVideoId"
                            @change="selectedVideoId = $event"
                        />
                    </div>
                    <div class="col-md-4" v-if="selectedVideoId">
                        <div class="my-2">Quiz</div>
                        <SelectDropdown
                            v-if="quizzes && quizzes.length"
                            :id="`form-video-quiz-subject`"
                            :options="quizzes.map((item) => ({ _id: item._id, title: item.title }))"
                            :value="selectedQuizId"
                            @change="selectedQuizId = $event"
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <Button
                            @click="fetchResults(false, true)"
                            class="btn btn-primary bnt-sm me-2"
                        >
                            <i class="fas fa-download"></i> Export Excel
                        </Button>
                        <Button @click="fetchResults(true)" class="btn btn-primary bnt-sm me-2">
                            <i class="fas fa-trash"></i> Clear Filters
                        </Button>
                        <Button @click="fetchResults" class="btn btn-primary bnt-sm me-2">
                            <i class="fas fa-list"></i> Load Results
                        </Button>
                    </div>
                </div>
            </div>

            <!-- resutls -->
            <div v-if="loading" class="d-flex justify-content-center my-5">
                <div class="spinner-border text-primary"></div>
            </div>

            <div v-if="totalResults" class="my-3">Total Results: {{ totalResults }}</div>
            <table
                v-if="!loading"
                class="table-responsive table table-light table-sm table-hover table-striped"
                style="font-size: 0.8em"
            >
                <thead>
                    <tr>
                        <td>Sl. no</td>
                        <td>Student Name</td>
                        <td>Subject</td>
                        <td>Video</td>
                        <td>Quiz</td>
                        <td>Total Questions</td>
                        <td>Correct Answers</td>
                        <td>Incorrect Answers</td>
                        <td>Not Attempted</td>
                        <td>Score</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(quizHistory, i) of quizHistories" :key="i">
                        <td>
                            {{ (page - 1) * limit + (i + 1) }}
                        </td>
                        <td>
                            {{ quizHistory["Student Name"] }}
                        </td>
                        <td>
                            {{ quizHistory["Subject"] }}
                        </td>
                        <td>
                            {{ quizHistory["Video"] }}
                        </td>
                        <td>
                            {{ quizHistory["Quiz"] }}
                        </td>
                        <td>
                            {{ quizHistory["Total Questions"] }}
                        </td>
                        <td>
                            {{ quizHistory["Correct Answers"] }}
                        </td>
                        <td>
                            {{ quizHistory["Incorrect Answers"] }}
                        </td>
                        <td>
                            {{ quizHistory["Not Attempted"] }}
                        </td>
                        <td>
                            {{ quizHistory["Score"] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
    </div>
</template>

<script>
import { baseURL, http } from "@/utils/http"
import Paginator from "@/components/other/Paginator"
import SelectDropdown from "@/components/crud/SelectDropdown"
import Button from "@/components/UI/Button"
import { durify } from "@/utils/common"
import DateRangeFilter from "@/components/UI/DateRangeFilter"

export default {
    components: { Paginator, SelectDropdown, Button, DateRangeFilter },
    data() {
        return {
            quizHistories: [],
            loading: false,
            page: 1,
            limit: 50,
            totalResults: 0,
            totalPages: 0,
            subjects: [],
            videos: [],
            quizzes: [],
            selectedSubjectId: "",
            selectedVideoId: "",
            selectedQuizId: "",
            processing: false,
            startDate: "",
            endDate: "",
            dateInitialized: false,
        }
    },
    async mounted() {
        this.fetchSubjects()
    },
    methods: {
        durify,
        async handleDateChange({ startDate, endDate }) {
            this.startDate = startDate
            this.endDate = endDate

            if (!this.dateInitialized) {
                this.dateInitialized = true
                await this.fetchResults()
            }
        },
        async fetchSubjects() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/subjects`)
                this.subjects = res.data.result
            } catch (err) {
                console.log("error in fetching subjects", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchVideos() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/videos/subject/${this.selectedSubjectId}`)
                this.videos = res.data.result
            } catch (err) {
                console.log("error in fetching videos", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchQuizzes() {
            if (this.processing) return
            this.processing = true
            try {
                const res = await http.get(`/quiz?video_id=${this.selectedVideoId}`)
                this.quizzes = res.data.result
            } catch (err) {
                console.log("error in fetching quizzes", err)
                this.$toast.error("Error occured")
            }
            this.processing = false
        },
        async fetchResults(clearFilters = false, downloadExcel = false) {
            if (clearFilters) {
                this.selectedVideoId = ""
                this.selectedSubjectId = ""
                this.selectedQuizId = ""
            }
            if (downloadExcel) {
                return window.open(
                    baseURL +
                        `/reports/quiz?page=${this.page}&limit=${this.limit}&videoId=${this.selectedVideoId}&subjectId=${this.selectedSubjectId}&quizId=${this.selectedQuizId}&excel=${downloadExcel}&start_date=${this.startDate}&end_date=${this.endDate}`
                )
            }
            this.totalResults = 0
            this.totalPages = 0
            this.loading = true
            try {
                const res = await http.get(
                    `/reports/quiz?page=${this.page}&limit=${this.limit}&videoId=${this.selectedVideoId}&subjectId=${this.selectedSubjectId}&quizId=${this.selectedQuizId}&start_date=${this.startDate}&end_date=${this.endDate}`
                )
                this.quizHistories = res.data.result
                this.totalResults = res.data.totalResults
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("error in loading video report", err)
                this.$toast.error("Error occured")
            }
            this.loading = false
        },
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchResults()
            }
        },
    },
    watch: {
        selectedSubjectId(e) {
            if (e) {
                this.fetchVideos()
            }
        },
        selectedVideoId(e) {
            if (e) {
                this.fetchQuizzes()
            }
        },
    },
}
</script>

<style></style>
