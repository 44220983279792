<template>
  <div>
    <div class="text-black font-bold">
      Create Discussion
      <span
        @click="showForm = !showForm"
        class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
      >
        <i class="fas fa-minus" v-if="showForm" />
        <i class="fas fa-add" v-else />
      </span>
    </div>
    <Card v-if="showForm">
      <form class="my-3" @submit.prevent="createDiscussion">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">Users</div>

            <div>
              <select
                required
                class="form-select"
                v-model="userId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select User</option>
                <option v-for="user of users" :value="user._id" :key="user._id">
                  {{ user.firstName }} {{ user.lastName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-2">Videos</div>

            <div>
              <select
                required
                class="form-select"
                v-model="videoId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select Video</option>
                <option
                  v-for="video of videos"
                  :value="video._id"
                  :key="video._id"
                >
                  {{ video.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">title*</div>
            <div>
              <input
                required
                v-model="title"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-2">Description</div>
            <div>
              <HtmlEditor
                @change="description = $event"
                v-model="description"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">
              Update Image <small>(recommended 100 px)</small>
            </div>

            <input
              type="file"
              class="form-control"
              title="imageUrl"
              @change="updateImage"
              accept="image/*"
            />
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ creatingDiscussion ? "Please wait..." : "Create" }}
        </Button>
      </form>
    </Card>
    <Card>
      <h1 class="font-semibold mb-3">Discussion</h1>
      <div class="grid grid-cols-3 bg-green-200 p-2">
        <div>Id</div>
        <div>Title</div>
        <div>Action</div>
      </div>
      <div
        class="p-4 text-center text-sm"
        v-if="discussions.length <= 0 && initialized"
      >
        No Discussion Found
      </div>
      <div
        class="grid grid-cols-3 p-2"
        v-for="discussion of discussions"
        :key="discussion._id"
      >
        <div>{{ discussion._id }}</div>
        <div>{{ discussion.title }}</div>

        <div>
          <router-link
            :to="{ name: 'discussion-edit', params: { id: discussion._id } }"
          >
            <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
          </router-link>
          <i
            class="cursor-pointer text-red-500 fas fa-trash"
            @click="deleteDiscussion(discussion._id)"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from "../../components/UI/Card.vue";
  import Button from "../../components/UI/Button.vue";
  import { http } from "../../utils/http";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        users: [],
        videos: [],
        discussions: [],
        videoId: "",
        userId: "",
        title: "",
        description: "",
        imageUrl: "",
        
        showForm: false,
        initialized: false,
        creatingDiscussion: false,
      };
    },
    mounted() {
      this.loadVideos();
      this.loadDiscussion();
    },
    methods: {
      updateImage(e) {
        this.imageUrl = e.target.files[0];
      },
     
      async loadVideos() {
        try {
          const res = await http.get("/videos");
          const res2 = await http.get("/users");
          this.videos = res.data.result;
          this.users = res2.data.result;
        } catch (err) {
          console.log("error in loading videos", err);
        }
        this.initialized = true;
      },
      async loadDiscussion() {
        try {
          const res = await http.get("/discussions");
          this.discussions = res.data.result;
        } catch (err) {
          console.log("error in loading discussions", err);
        }
        this.initialized = true;
      },

      async createDiscussion() {
        this.creatingDiscussion = true;
        try {
          const formData = new FormData();
          formData.append("videoId", this.videoId || "");
          formData.append("userId", this.userId || "");
          formData.append("title", this.title || "");
          formData.append("description", this.description || "");
          formData.append("imageUrl", this.imageUrl || "");
         
          console.log("formData", formData);

          const createdRes = await http.post("/discussions", formData);
          const createdcategory = createdRes.data.result;
          this.discussions.push(createdcategory);
          this.title = "";
          this.description = "";
          this.imageUrl = "";

          this.$toast.success("Discussion Added Successfully");
          window.location.href = "/discussions";
        } catch (err) {
          console.log("error in Updating discussions", err);
          this.$toast.error("Some error occured");
        }

        this.creatingDiscussion = false;
      },
      async deleteDiscussion(id) {
        try {
          if (confirm("Do you really want to delete?")) {
            await http.delete("/discussions/" + id);
            this.$toast.success("Discussion Deleted Successfully");
            this.discussions = this.discussions.filter(
              (item) => item.id !== id
            );
            window.location.href = "/discussions";
          }
        } catch (err) {
          console.log("error in creating video", err);
          this.$toast.error("Some error occured");
        }
      },
    },
  };
</script>

<style></style>
