<template>
    <div :class="['page-item', { active }]" @click="$emit('change', page)">
        <div>
            {{ page }}
        </div>
    </div>
</template>

<script>
export default {
    emits: ["change"],
    data() {
        return {}
    },
    props: {
        page: { type: Number, default: () => 1 },
        active: { type: Boolean, default: () => false },
    },
    mounted() {},
}
</script>

<style lang="scss">
.page-item {
    width: 35px;
    height: 35px;
    font-size: 0.9em;
    margin: 10px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    background-color: #eee;
    cursor: pointer;

    &:hover {
        background-color: #ddd;
    }
    &.active {
        background-color: #ccf;
    }
}
</style>
