<template>
    <div>
        <div class="mb-3">
            <h4 class="h6"><strong>Filter</strong></h4>
            <div class="bg-white p-3">
                <div class="row">
                    <div class="col-md-4">
                        <div class="my-2">Select Category</div>
                        <div>
                            <SelectDropdown
                                :id="`form-Category`"
                                :options="
                                    ticketCategories.map((item) => ({
                                        _id: item._id,
                                        title: item.name,
                                    }))
                                "
                                :value="selectedCategory"
                                @change="selectedCategory = $event"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="noResults" class="my-3">No Results</div>
            </div>
        </div>
        <CrudIndex
            v-if="!loading"
            title="Tickets"
            :viewColumns="columns"
            fetchUrl="/tickets"
            createUrl="/tickets"
            updateRootUrl="/tickets"
            deleteRootUrl="/tickets"
            :createFields="createFields"
            :dataItemFilter="dataItemFilter"
            :editingFormatter="editingFormatter"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"
import { mapState } from "vuex"

export default {
    data() {
        return {
            selectedCategory: "",
            loading: true,
            createFields: [],
            ticketStatuses: [],
            ticketPriorities: [],
            ticketCategories: [],
            columns: [
                // createColumn("Id", "_id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Category", "categoryId.name", "string"),
                createColumn("Status", "statusId.name", "string"),
                createColumn("Priority", "priorityId.name", "string"),
            ],
        }
    },
    computed: {
        ...mapState("auth", ["user"]),
    },
    components: { CrudIndex, SelectDropdown },
    async mounted() {
        const { ticketStatuses, ticketPriorities, ticketCategories } = await this.initMasters()

        this.ticketStatuses = ticketStatuses
        this.ticketPriorities = ticketPriorities
        this.ticketCategories = ticketCategories

        this.createFields = [
            createField("Name*", "name", "text", true),
            createField(
                "Category*",
                "categoryId",
                "select",
                true,
                "",
                this.ticketCategories.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
            createField(
                "Status*",
                "statusId",
                "select",
                true,
                "",
                this.ticketStatuses.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
            createField(
                "Priority*",
                "priorityId",
                "select",
                true,
                "",
                this.ticketPriorities.map((item) => ({
                    _id: item._id,
                    title: item.name,
                }))
            ),
            createField("userId", "userId", "hidden", this.user && this.user.id),
            createField("Description", "description", "textarea", false),
            createField("Attachment 1", "attachment1", "file", false),
            createField("Attachment 2", "attachment2", "file", false),
            createField("Attachment 3", "attachment3", "file", false),
        ]
        this.loading = false
    },
    methods: {
        async initMasters() {
            const res = await http.get("/tickets/init")
            return res.data
        },
        dataItemFilter(items) {
            if (this.selectedCategory) {
                items = items.filter((item) => item.categoryId._id == this.selectedCategory)
            }
            if (!items.length) {
                this.noResults = true
            } else {
                this.noResults = false
            }
            return items
        },
        editingFormatter(item) {
            return {
                ...item,
                categoryId: item.categoryId ? item.categoryId._id : "",
                userId: item.userId ? item.userId._id : "",
                statusId: item.statusId ? item.statusId._id : "",
                priorityId: item.priorityId ? item.priorityId._id : "",
            }
        },
    },
}
</script>

<style></style>
