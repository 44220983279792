<template>
    <button @click="$emit('click')" class="app-btn my-3 mt-4 border-none">
        <slot />
    </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.app-btn {
    font-size: 0.8em;
    color: white;
    padding: 7px;
    border-radius: 5px;
    min-width: 120px;
    background-color: $primaryColor;
    &:hover {
        background-color: lighten($primaryColor, 15%);
    }
}
</style>
