<template>
    <div>
        <nav class="navbar-header shadow">
            <div class="container-fluid header-link-container">
                <strong>{{ pageTitle || "Admin Panel" }}</strong>
                <div style="font-size: 0.9em">
                    <router-link exact-active-class="active-link" to="/my-profile" class="mr-4">
                        My Profile
                    </router-link>
                    <button class="logout-btn" @click="logoutUtil">Logout</button>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex"

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState("misc", ["pageTitle"]),
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        logoutUtil() {
            this.logout()
            window.location.reload()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.navbar-header {
    background-color: white;
    padding: 15px;
}
.header-link-container {
    display: flex;
    justify-content: space-between;
}
.active-link {
    color: $primaryColor;
}
.logout-btn {
    &:hover {
        color: $primaryColor;
    }
}
</style>
