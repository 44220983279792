<template>
    <div>
        <div class="text-black font-bold mb-3">
            <h3>General Stats</h3>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row gy-3">
                        <div class="col-md-4">
                            <div class="report-view-card">
                                <div class="report-title-sm">Number Of Views</div>
                                <div class="report-crunch">
                                    {{
                                        dashboardReports
                                            ? millify(dashboardReports.totalViews)
                                            : "..."
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="report-view-card">
                                <div class="report-title-sm">Unique Users</div>
                                <div class="report-crunch">
                                    {{
                                        dashboardReports
                                            ? millify(dashboardReports.totalUniqueUsers)
                                            : "..."
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="report-view-card">
                                <div class="report-title-sm">Total Quiz Questions Attempted</div>
                                <div class="report-crunch">
                                    {{
                                        dashboardReports
                                            ? millify(dashboardReports.totalQuestionsAttempted)
                                            : "..."
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 class="my-3 report-title">States</h3>
                    <SelectDropdown
                        :id="`form-subject`"
                        :options="
                            states.map((item) => ({
                                _id: item._id,
                                title: item.name,
                            }))
                        "
                        :value="selectedStates"
                        :multiple="true"
                        @change="selectedStates = $event"
                    />
                    <div class="">
                        <Button @click="reloadAllData">{{
                            loading ? "Loading..." : "Load Data"
                        }}</Button>
                    </div>
                    <div>
                        <div class="report-title my-3">Top Users</div>
                        <div class="mb-3">
                            <DateRangeFilter
                                :maxRangeDays="50"
                                compactMode
                                @change="handleDateChange"
                            />
                        </div>
                        <div>
                            <table class="table table-sm table-light table-bordered table-hover">
                                <thead class="">
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Roll Number</th>
                                        <th>Total Videos Seen</th>
                                        <th>Total Questions Attempted</th>
                                        <th>Total Questions Correctly Answered</th>
                                    </tr>
                                </thead>
                                <tbody v-if="topUsers">
                                    <tr v-for="user of topUsers" :key="user._id">
                                        <td>{{ user.fullName }}</td>
                                        <td>{{ user.rollNumber }}</td>
                                        <td>{{ user.totalVideosSeen }}</td>
                                        <td>{{ user.totalQuestionsAttempted }}</td>
                                        <td>{{ user.totalQuestionsCorrectlyAnswered }}</td>
                                    </tr>
                                    <tr v-if="!loadingTopUsers">
                                        <th>Total</th>
                                        <th></th>
                                        <th>
                                            {{
                                                topUsers.reduce(
                                                    (sum, item) => sum + item.totalVideosSeen,
                                                    0
                                                )
                                            }}
                                        </th>
                                        <th>
                                            {{
                                                topUsers.reduce(
                                                    (sum, item) =>
                                                        sum + item.totalQuestionsAttempted,
                                                    0
                                                )
                                            }}
                                        </th>
                                        <th>
                                            {{
                                                topUsers.reduce(
                                                    (sum, item) =>
                                                        sum + item.totalQuestionsCorrectlyAnswered,
                                                    0
                                                )
                                            }}
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="loadingTopUsers" class="my-3">
                                Analyzing (time: 2-5min)...
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" v-if="dashboardReports">
                    <div>
                        <div class="report-title">Top 10 Videos</div>
                        <div>
                            <div v-if="loading" class="my-3">Loading...</div>
                            <canvas id="videoChart" style="min-height: 270px"></canvas>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="report-title">Top 10 Watched Subjects</div>
                        <div>
                            <div v-if="loading" class="my-3">Loading...</div>
                            <canvas id="subjectChart" style="min-height: 270px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from "../utils/http"
import millify from "millify"
import { mapState, mapActions } from "vuex"
import DateRangeFilter from "@/components/UI/DateRangeFilter"
import SelectDropdown from "../components/crud/SelectDropdown.vue"
import Button from "../components/UI/Button.vue"

export default {
    data() {
        return {
            dashboardReports: null,
            initialized: false,
            loading: true,
            startDate: "",
            endDate: "",
            selectedStates: [],
        }
    },
    mounted() {
        this.loadHomeData()
    },
    components: { Button, SelectDropdown, DateRangeFilter },
    computed: {
        ...mapState("misc", ["loadingTopUsers", "topUsers", "states"]),
    },
    methods: {
        ...mapActions("misc", ["loadTopUsers", "setData"]),
        millify,
        reloadAllData() {
            this.loadHomeData()
            this.loadTopUsers({
                startDate: this.startDate,
                endDate: this.endDate,
                states: this.selectedStates.join(","),
            })
        },
        async handleDateChange({ startDate, endDate }) {
            this.startDate = startDate
            this.endDate = endDate
            this.setData({
                topUsers: [],
            })
            await this.loadTopUsers({ startDate, endDate, states: this.selectedStates.join(",") })
            // don't load home data as its not based on date
        },
        async loadHomeData() {
            this.loading = true
            this.dashboardReports = null
            try {
                const dashboardReports = await http.get(
                    `/reports/dashboard?fetchUsers=false&start_date=${this.startDate}&end_date=${
                        this.endDate
                    }&states=${this.selectedStates.join(",")}`
                )
                this.dashboardReports = dashboardReports.data.result

                if (this.dashboardReports) {
                    setTimeout(() => {
                        this.createCharts({
                            el: "videoChart",
                            title: "Top 10 Videos",
                            labels: this.dashboardReports.topVideos.map((item) =>
                                item.title.slice(0, 12)
                            ),
                            datasets: this.dashboardReports.topVideos.map(
                                (item) => item.totalWatchCount
                            ),
                            backgroundColor: "#6B007B",
                            borderColor: "#9307A8",
                        })
                        this.createCharts({
                            el: "subjectChart",
                            title: "Top 10 Watched Subjects",
                            labels: this.dashboardReports.topSubjects.map((item) =>
                                item.title.slice(0, 12)
                            ),
                            datasets: this.dashboardReports.topSubjects.map((item) => item.views),
                            backgroundColor: "#6B007B",
                            borderColor: "#9307A8",
                        })
                    })
                }
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            this.loading = false
        },

        async createCharts({ el, title, labels, datasets, backgroundColor, borderColor }) {
            const data = {
                labels,
                datasets: [
                    {
                        label: "Views",
                        data: datasets,
                        backgroundColor, // You can set the color of the bars here
                        borderColor, // You can set the border color of the bars here
                        borderWidth: 1,
                    },
                ],
            }

            const ctx = document.getElementById(el).getContext("2d")
            Chart.register(ChartDataLabels)

            const horizontalChart = new Chart(ctx, {
                type: "bar", // Set the chart type to 'horizontalBar' for a horizontal bar chart
                data: data,

                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: true, // Hide the X-axis,
                            gridLines: {
                                display: false,
                            },
                        },
                        y: {
                            display: true, // Hide the Y-axis
                            gridLines: {
                                display: false,
                            },
                        },
                    },
                    indexAxis: "y",
                    // Elements options apply to all of the options unless overridden in a dataset
                    // In this case, we are setting the border of each horizontal bar to be 2px wide
                    elements: {
                        bar: {
                            borderWidth: 2,
                        },
                    },
                    responsive: true,
                    plugins: {
                        datalabels: {
                            display: true, // Show data labels if needed (this line is optional and depends on your use case)
                            color: "white",
                            align: "end",
                        },
                        legend: {
                            position: "bottom",
                        },
                        title: {
                            display: true,
                            text: title,
                        },
                    },
                    data: {
                        datasets: [
                            {
                                // Change options only for labels of THIS DATASET
                                datalabels: {
                                    color: "white",
                                },
                            },
                        ],
                    },
                },
            })
        },
    },
}
</script>

<style scoped>
.title {
    font-weight: bold;
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 5px;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    /* border: 2px solid #588c9dd1; */
    border-radius: 20px;
}
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.report-view-card {
    background-color: rgb(114, 20, 114);
    color: white;
    border: 1px solid purple;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    height: 100%;
}
table {
    font-size: 0.8em;
}
.report-title {
    font-weight: bold;
    font-size: 1.2em;
}
.report-title-sm {
    font-size: 0.9em;
}
.report-crunch {
    font-weight: bold;
    font-size: 1.5em;
}
thead > tr > th {
    color: white;
    background-color: #8d578d;
}
</style>
