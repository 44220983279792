<template>
    <div class="text-black font-bold">
        Edit Quiz Answer

        <Card>
            <form class="my-3" @submit.prevent="updateQuizAnswer">
                <div class="row">
                    <div class="col-6">
                        <div class="mb-2">Quiz</div>

                        <select
                            class="form-select"
                            v-model="quizAnswer.quizQuestionMasterId"
                            aria-label=".form-select-sm example"
                        >
                            <option :selected="!quizAnswer.quizQuestionMasterId">
                                Select Option
                            </option>
                            <option
                                :value="questionItem._id"
                                v-for="questionItem of questionMaster"
                                :key="questionItem._id"
                            >
                                {{ questionItem.title }}
                            </option>
                        </select>
                    </div>

                    <div class="col-6">
                        <div class="mb-2">Quiz Answer Options</div>
                        <select
                            class="form-select"
                            v-model="quizAnswer.quizAnswerOptionMasterId"
                            aria-label=".form-select-sm example"
                        >
                            <option :selected="!quizAnswer.quizAnswerOptionMasterId">
                                Select Option
                            </option>
                            <option
                                :value="answerOption._id"
                                v-for="answerOption of answerOptions"
                                :key="answerOption._id"
                            >
                                {{ answerOption.title }}
                            </option>
                        </select>
                    </div>
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ updatingQuizAnswer ? "Please wait..." : "Update" }}
                </Button>
            </form>
        </Card>
    </div>
</template>

<script>
import { http, serverURL } from "../../utils/http"
import Card from "../../components/UI/Card.vue"
import HtmlEditor from "../../components/other/HtmlEditor.vue"
import Button from "../../components/UI/Button.vue"

export default {
    components: { Card, Button, HtmlEditor },
    data() {
        return {
            questionMaster: [],
            answerOptions: [],
            serverURL,
            quizAnswer: {},
            updatingQuizAnswer: false,
            deleteImage: false,
            deleteFeaturedImage: false,
        }
    },

    async mounted() {
        try {
            const res = await http.get("/quiz-answers/" + this.$route.params.id)
            this.quizAnswer = res.data.result
        } catch (err) {
            console.log("error in loading quizAnswer", err)
        }
        this.loadQuiz()
    },

    methods: {
        async loadQuiz() {
            try {
                const res = await http.get("/quiz-questions")
                const res2 = await http.get("/quiz-answer-options")
                this.questionMaster = res.data.result
                this.answerOptions = res2.data.result
            } catch (err) {
                console.log("error in loading questionMaster", err)
            }
            this.initialized = true
        },

        async updateQuizAnswer() {
            this.updatingQuizAnswer = true

            try {
                await http.patch("/quiz-answers/" + this.$route.params.id, {
                    quizQuestionMasterId: this.quizAnswer.quizQuestionMasterId,
                    quizAnswerOptionMasterId: this.quizAnswer.quizAnswerOptionMasterId,
                })
                // this.cat.push(updatedDataItem)
                this.$toast.success("Quiz Answer Updated Successfully")
                window.location.href = "/quiz-answer"
            } catch (err) {
                console.log("error in Updating data", err)
                this.$toast.error("Some error occured")
            }

            this.updatingQuizAnswer = false
        },
    },
}
</script>

<style></style>
