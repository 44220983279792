<template>
  <div>
    <div class="text-black font-bold">
      Create Video Progress
      <span
        @click="showForm = !showForm"
        class="bg-white p-1 px-2 mx-3 rounded text-green-500 cursor-pointer"
      >
        <i class="fas fa-minus" v-if="showForm" />
        <i class="fas fa-add" v-else />
      </span>
    </div>
    <Card v-if="showForm">
      <form class="my-3" @submit.prevent="createVideoProgress">
        <div class="row">
          

          <div class="col-6">
            <div class="mb-2">Video</div>

            <div>
              <select
                required
                class="form-select"
                v-model="videoId"
                aria-label=".form-select-sm example"
              >
                <option selected disabled>Select Quiz</option>
                <option
                  v-for="video of videos"
                  :value="video._id"
                  :key="video._id"
                >
                  {{ video.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">StartTimeInSeconds</div>
            <div>
              <input
                required
                v-model="startTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">EndTimeInSeconds</div>
            <div>
              <input
                required
                v-model="endTimeInSeconds"
                type="number"
                class="form-control"
                min="0"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">Subtitle</div>
            <div>
              <input
                required
                v-model="subtitle"
                type="text"
                class="form-control"
                
              />
            </div>
          </div>
          
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ creatingVideoSubtitle ? "Please wait..." : "Create" }}
        </Button>
      </form>
    </Card>
    <Card>
      <h1 class="font-semibold mb-3">Video Subtitle</h1>
      <div class="grid grid-cols-3 bg-green-200 p-2">
        <div>Id</div>
        <div>Subtitle</div>
        <div>Action</div>
      </div>
      <div
        class="p-4 text-center text-sm"
        v-if="videoSubtitles.length <= 0 && initialized"
      >
        No VideoSubtitles Found
      </div>
      <div
        class="grid grid-cols-3 p-2"
        v-for="videoSubtitle of videoSubtitles"
        :key="videoSubtitle._id"
      >
        <div>{{ videoSubtitle._id }}</div>
        <div>{{ videoSubtitle.subtitle }}</div>

        <div>
          <router-link
                :to="{
                  name: 'video-subtitle-edit',
                  params: { id: videoSubtitle._id },
                }"
              >
                <i class="cursor-pointer text-green-500 pr-3 fas fa-edit" />
              </router-link>
          <i
            class="cursor-pointer text-red-500 fas fa-trash"
            @click="deleteVideoSubtitle(videoSubtitle._id)"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
  import Card from "../../components/UI/Card.vue";
  import Button from "../../components/UI/Button.vue";
  import { http } from "../../utils/http";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        
        videoId: "",
        startTimeInSeconds:"", endTimeInSeconds:"", subtitle:"",
        
        videos: [],
        
        videoSubtitles: [],
        showForm: false,
        initialized: false,
        creatingVideoSubtitle: false,
      };
    },
    mounted() {
      this.loadQuizHistory();
      this.loadUsers();
    },
    methods: {
      

      async loadUsers() {
        try {
         
          const res2 = await http.get("/videos");
         
          this.videos = res2.data.result;
        } catch (err) {
          console.log("error in loading  videos", err);
        }
        this.initialized = true;
      },
      async loadQuizHistory() {
        try {
          const res = await http.get("/video-subtitles");
          this.videoSubtitles = res.data.result;
        } catch (err) {
          console.log("error in loading Quiz Questions", err);
        }
        this.initialized = true;
      },

      async createVideoProgress() {
        this.creatingVideoSubtitle = true;
        try {
          await http.post("/video-subtitles", {
            
            videoId: this.videoId,
            startTimeInSeconds: this.startTimeInSeconds,
            endTimeInSeconds: this.endTimeInSeconds,
            subtitle: this.subtitle,
            
          });

         
          this.videoId = "";
          this.startTimeInSeconds = "";
          this.endTimeInSeconds = "";
          this.subtitle = "";
        

          this.$toast.success("Subtitle Added Successfully");
          window.location.href = "/video-subtitle";
        } catch (err) {
          console.log("error in Adding Video Subtitle", err);
          this.$toast.error("Some error occured");
        }

        this.creatingVideoSubtitle = false;
      },
      async deleteVideoSubtitle(id) {
        try {
          if (confirm("Do you really want to delete?")) {
            await http.delete("/video-subtitles/" + id);
            this.$toast.success("Video-subtitle option Deleted Successfully");
            this.videoSubtitles = this.videoSubtitles.filter(
              (item) => item.id !== id
            );
            window.location.href = "/video-subtitle";
          }
        } catch (err) {
          console.log("error in creating subtitle", err);
          this.$toast.error("Some error occured");
        }
      },
    },
  };
</script>

<style></style>
