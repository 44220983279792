import { render, staticRenderFns } from "./AnswerOptionCreator.vue?vue&type=template&id=6dd31193&scoped=true&"
import script from "./AnswerOptionCreator.vue?vue&type=script&lang=js&"
export * from "./AnswerOptionCreator.vue?vue&type=script&lang=js&"
import style0 from "./AnswerOptionCreator.vue?vue&type=style&index=0&id=6dd31193&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd31193",
  null
  
)

export default component.exports