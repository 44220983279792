<template>
    <div>
        <div class="my-2">
            <input class="form-check-input" type="checkbox" :id="id" v-model="showCoordinates" />
            <label class="mx-2" :for="id">{{ title }}</label>
        </div>
        <div class="d-flex align-items-center" style="gap: 5px" v-if="showCoordinates">
            <div>X:</div>
            <input
                class="form-control form-control-sm mb-2"
                type="number"
                v-model="coordX"
                placeholder="X Coordinate"
            />
        </div>
        <div class="d-flex align-items-center" style="gap: 5px" v-if="showCoordinates">
            <div>Y:</div>
            <input
                class="form-control form-control-sm"
                type="number"
                v-model="coordY"
                placeholder="Y Coordinate"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: false,
        },
        title: {
            type: String,
            default: false,
        },
        currentShowCoordinates: {
            type: Boolean,
            default: false,
        },
        currentCoordX: {
            type: String,
            default: false,
        },
        currentCoordY: {
            type: String,
            default: false,
        },
    },
    data() {
        return { showCoordinates: false, coordX: "", coordY: "" }
    },
    mounted() {
        this.showCoordinates = this.currentShowCoordinates
        this.coordX = this.currentCoordX
        this.coordY = this.currentCoordY
    },
    watch: {
        showCoordinates(e, oldE) {
            if (e != oldE) {
                this.$emit("check", e)
            }
        },
        coordX(e, oldE) {
            if (e != oldE) {
                this.$emit("coordX", this.coordX)
            }
        },
        coordY(e, oldE) {
            if (e != oldE) {
                this.$emit("coordY", this.coordY)
            }
        },
    },
}
</script>

<style></style>
