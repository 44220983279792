<template>
  <div class="text-black font-bold">
    Edit Subtitle

    <Card>
      <form class="my-3" @submit.prevent="updateVideoSubtitle">
        <div class="row">
          <div class="col-6">
            <div class="mb-2">Video</div>
            <select
              class="form-select"
              v-model="videoSubtitle.videoId"
              aria-label=".form-select-sm example"
            >
              <option :selected="!videoSubtitle.videoId">Select Option</option>
              <option
                :value="video._id"
                v-for="video of videos"
                :key="video._id"
              >
                {{ video.title }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <div class="my-2">StartTimeInSeconds</div>
            <div>
              <input
                v-model="videoSubtitle.startTimeInSeconds"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="my-2">EndTimeInSeconds</div>
            <div>
              <input
                required
                v-model="videoSubtitle.endTimeInSeconds"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="my-2">Subtitle</div>
            <div>
              <input v-model="videoSubtitle.subtitle" type="text" class="form-control" />
            </div>
          </div>
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ updatingSubtitle ? "Please wait..." : "Update" }}
        </Button>
      </form>
    </Card>
  </div>
</template>

<script>
  import { http, serverURL } from "../../utils/http";
  import Card from "../../components/UI/Card.vue";
  import HtmlEditor from "../../components/other/HtmlEditor.vue";
  import Button from "../../components/UI/Button.vue";

  export default {
    components: { Card, Button, HtmlEditor },
    data() {
      return {
        videos: [],
        answerOptions: [],

        serverURL,
        videoSubtitle: {},

        updatingSubtitle: false,
        deleteImage: false,
        deleteFeaturedImage: false,
      };
    },

    async mounted() {
      try {
        const res = await http.get("/video-subtitles/" + this.$route.params.id);
       
        this.videoSubtitle = res.data.result;
        
      } catch (err) {
        console.log("error in loading videoSubtitle", err);
      }
      this.loadVideo();
    },

    methods: {
      async loadVideo() {
        try {
          const res = await http.get("/videos");
          
          this.videos = res.data.result;
        } catch (err) {
          console.log("error in loading Video", err);
        }
        this.initialized = true;
      },

      async updateVideoSubtitle() {
        this.updatingSubtitle = true;

        try {
          await http.patch("/video-subtitles/" + this.$route.params.id, {
            videoId: this.videoSubtitle.videoId,
            startTimeInSeconds: this.videoSubtitle.startTimeInSeconds,
            endTimeInSeconds: this.videoSubtitle.endTimeInSeconds,
            subtitle: this.videoSubtitle.subtitle,
          });
          
          // this.cat.push(updatedDataItem)
          this.$toast.success("Video Subtitle Updated Successfully");
          window.location.href = "/video-subtitle";
        } catch (err) {
          console.log("error in Updating data", err);
          this.$toast.error("Some error occured");
        }

        this.updatingSubtitle = false;
      },
    },
  };
</script>

<style></style>
