import formatDuration from "format-duration"
import {
    createField as createFieldUtil,
    createFormData as createFormDataUtil,
    createColumn as createColumnUtil,
} from "../components/crud/utils"

const durify = (seconds) => formatDuration(seconds * 1000)

const formatIsoDate = (date = new Date()) => new Date(date).toISOString().slice(0, 10)
const daysToMs = (days) => days * 24 * 60 * 60 * 1000

function createField(label, field = "", type = "text", required = false, value = "", options = []) {
    // for custom form field object for crud form
    return createFieldUtil(label, field, type, required, value, options)
}
function createColumn(label, field, type) {
    // for vue good table
    return createColumnUtil(label, field, type)
}
function createFormData(formValues) {
    return createFormDataUtil(formValues)
}

function isMobileNumber(val) {
    return val.length == 10 && /[0-9 -()+]+$/.test(val)
}
function isValidEmail(val) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        val
    )
}
function errMsg(err, defaultMsg = "Some error occured") {
    // return suitable error message for catch responses
    let message = ""
    if (err) {
        message = err.response && err.response.data && err.response.data.message
    }
    return message || defaultMsg
}
export {
    createField,
    createColumn,
    createFormData,
    durify,
    isMobileNumber,
    isValidEmail,
    formatIsoDate,
    daysToMs,errMsg
}
